const days = [
    {
      name: "monday",
      value: 1,
    },
    {
      name: "tuesday",
      value: 2,
    },
    {
      name: "wednesday",
      value: 3,
    },
    {
      name: "thursday",
      value: 4,
    },
    {
      name: "friday",
      value: 5,
    },
    {
      name: "saturday",
      value: 6,
    },
    {
      name: "sunday",
      value: 0,
    },
];
  
export default days