export const widgetsComponents = [
    'Discount',
    'LastMinute',
    'Notification',
    'NotificationSocialPressure',
    'SocialPressure',
    'OffersInfo',
    'EarlyBooking',
    'ExitCard'
]

export const noTagsArray = ['Discount', 'OffersInfo', 'NotificationSocialPressure', 'LastMinute', 'ExitCard']

export const imagesWidgets = ['ExitCard', 'LastMinute']