import {  fetchit } from 'lib'

import {
    API_FIDELITY
} from 'config/api'


export function createFidelityActivity(data, accessToken) {
    return fetchit(API_FIDELITY+'/staff/newactivity', data, 'POST', accessToken)
}

export function editFidelityActivity(data, ActivityId, accessToken) {
    return fetchit(API_FIDELITY+'/'+ActivityId, data, 'PATCH', accessToken)
}


export function getFidelityActivities({ placeId, accessToken, limit=10, page=0 }) {
    return fetchit(API_FIDELITY+`/?&limit=${limit}&page=${page}&place=${placeId}`, null, 'GET', accessToken)
}

export function getFidelityActivitiesByUserId({ userId, placeId, accessToken, limit = 10, page = 0 }) {
    if (!userId || !placeId) {
        console.warn('customer or place id is missing')
        return
    }
    return fetchit(API_FIDELITY+`/users/${userId}/${placeId}/?limit=${limit}&page=${page}`, null, 'GET', accessToken)
}

// Search For a customer with Email or ID
export function getCustomer({ customer, placeId, accessToken }) {
    if (!customer) {
        console.warn('customer id is missing')
        return
    }
    return fetchit(API_FIDELITY+`/customers?place=${placeId}&customer=`+customer, null, 'GET', accessToken)
}