import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import { Link } from "react-router-dom";
import { Title } from "components/Typography";
import themes from "./themes";

export default function Templates() {
  const { t } = useTranslation();

  return (
    <Layout>
      <div style={{ padding: "20px" }}>
        <Spacing bottom={"lg"} />
        <Title>{t("title.start.design")}</Title>

        <CardsContainer>
          {themes.map((theme, k) => (
            <Card {...theme} key={theme.name + k} to={'/campaigns/editor/'+theme.fileName} target="_blank" rel="noopener noreferrer">
              <ShowOnHover>{ t('customize') }</ShowOnHover>
            </Card>
          ))}
        </CardsContainer>
      </div>
    </Layout>
  );
}

const ShowOnHover = styled.div`
  display: none;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content:center;
  align-items: center;
  font-family:${p => p.theme.font.title} ;
  color: black;
`;

const Card = styled(Link)`
  position: relative;
  height: 500px;
  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border: 1px solid rgba(0,0,0,.2);
  cursor: pointer;

  &:hover ${ShowOnHover} {
    display: flex;
  }
`;

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;
