import {  fetchit } from 'lib'
import {
    API_EMAIL_MARKETING
} from 'config/api'

export function createEmailQueue(data, accessToken) {
    return fetchit(API_EMAIL_MARKETING+'/queue', data, 'POST', accessToken)
}

export function getEmailQueue({ placeId, accessToken, limit=8, page=0 }) {
    if(!placeId) return
    return fetchit(`${API_EMAIL_MARKETING}/queue?sortBy=updatedAt:desc&limit=${limit}&page=${page}&place=${placeId}`, null, 'GET', accessToken)
}

export function createEmailTemplate(data, accessToken) {
    return fetchit(API_EMAIL_MARKETING, data, 'POST', accessToken)
}

export function editEmailTemplate(data, emailTemplateId, accessToken) {
    return fetchit(API_EMAIL_MARKETING+'/'+emailTemplateId, data, 'PATCH', accessToken)
}

export function getEmailTemplates({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_EMAIL_MARKETING+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteEmailTemplate(emailTemplateId, accessToken) {
    if(!emailTemplateId) return
    return fetchit(API_EMAIL_MARKETING+'/'+emailTemplateId, null, 'DELETE', accessToken)
}
