import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import NewCampaign from "./NewCampaign";
import CampaignHistory from "./CampaignHistory";

const Editor = lazy(() => import("./Editor"));

export default function HomeRoutes() {
  return (
    <Routes>
      <Route path="new" element={<NewCampaign />} />
      <Route path="history" element={<CampaignHistory />} />
      <Route
        path="editor/:templateId"
        element={
          <Suspense fallback={<>Loading Editor...</>}>
            <Editor />
          </Suspense>
        }
      />
    </Routes>
  );
}
