import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import Toggle from "react-toggle";
import { Row, Column } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Error,
  TextArea,
  Select,
  MultiSelect,
} from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";
import currencies from "config/currencies";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  categoryList,
  departmentList,
}) {
  const { t } = useTranslation();
  const [takeOrders, setTakeOrders] = useState(true);
  const [visible, setVisible] = useState(true);
  const [media, setMedia] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;
    const categories = data?.categories
      ? data.categories.map((s) => s.value)
      : [];
    onSubmit({ ...data, media: mediaIds, categories, visible, takeOrders });
  };

  const handleGallery = (data) => {
    setMedia(data);
  };

  useEffect(() => {
    if (defaultValues) {
      if (!!defaultValues?.media) {
        setMedia(defaultValues.media);
      }
      setTakeOrders(!!defaultValues?.takeOrders);
      setVisible(!!defaultValues?.visible);
    }
  }, [defaultValues]);

  const catOptions =
    categoryList && categoryList[0]
      ? categoryList
          .filter((c) => c.parent)
          .map((cat) => {
            return { value: cat._id, label: cat.name };
          })
      : null;

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.product.name")}
          />
          {errors.name && <Error>Product Name is required</Error>}
        </Row>
        <Row>
          <TextArea
            {...register("description")}
            aria-invalid={errors.description ? "true" : "false"}
            placeholder={t("form.category.description")}
          />
        
        </Row>

        <Row>
          {departmentList && departmentList[0] && (
            <Select
              {...register("department")}
              placeholder={t("form.category.parent")}
              small
            >
              <option value={""}>{t("notify.department")}</option>
              {departmentList.map((c, k) => (
                <option value={c._id} key={c + k}>
                  {c.name}
                </option>
              ))}
            </Select>
          )}
        </Row>

        <Spacing bottom={"sm"} />
        {catOptions && (
          <Controller
            name="categories"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={catOptions}
                placeholder={t("categories")}
                {...field}
              />
            )}
          />
        )}

        <Row>
          <Column>
            <Input {...register("price")} placeholder={t("form.price")} />
          </Column>
          <Column>
         
              <Select
                {...register("currency")}
                placeholder={t("form.currency")}
                small
              >
                <option value={""}>{t("form.select.currency")}</option>
                {currencies.map((c, k) => (
                  <option value={c.cc} key={c + k}>
                    {c.name}
                  </option>
                ))}
              </Select>
         
          </Column>
        </Row>
        <Input {...register("duration")} placeholder={t("duration.minutes")} type='number'/>

        <Spacing bottom={"md"} />
        <ModalGallery isMany onClick={handleGallery} />
        <Gallery medias={media} small />
        <Row>
          <Column>
            <Toggle
              checked={takeOrders}
              id="take-orders"
              onChange={() => setTakeOrders(!takeOrders)}
            />
            <label htmlFor="take-orders">{t("form.label.takeorder")}</label>
          </Column>
        </Row>
        <Row>
          <Toggle
            checked={visible}
            id="visible"
            onChange={() => setVisible(!visible)}
          />
          <label htmlFor="visible">{t("form.label.visible")}</label>
        </Row>
        <Spacing bottom={"md"} />
        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
  departmentList: PropTypes.array
};
