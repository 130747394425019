import { Player } from "@lottiefiles/react-lottie-player";

export default function HomeLine() {
  return (
  <Player
      autoplay
      loop
      src="https://assets9.lottiefiles.com/datafiles/9jPPC5ogUyD6oQq/data.json"
      style={{  
        height:" 200px"}}
    />
  )




}
