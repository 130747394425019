import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Column, Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import currencies from "config/currencies";
import { Input, Button, Error, Select } from "components/Form";


export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm
}) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  const watchAllFields = watch();


  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = async (data) => {
    const locales = data?.locales ? data.locales.map((s) => s.value) : [];
    data.locales = [...locales];
    onSubmit(data);
  };

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Column>
          <Select
                {...register("fidelityCurrency")}
                placeholder={t("form.currency")}
                small
              >
                <option value={""}>{t("form.select.currency")}</option>
                {currencies.map((c, k) => (
                  <option value={c.cc} key={c + k}>
                    {c.name}
                  </option>
                ))}
              </Select>
        
            {errors.fidelityCurrency && <Error>Default currency is required</Error>}
          </Column>
          <Column>
          <Input
              {...register("fidelityUnit", { required: true })}
              placeholder={t("form.fidelityUnit")}
            />
            {errors.fidelityUnit && <Error>Default unit is required</Error>}
          </Column>
        </Row>

        {watchAllFields && watchAllFields.fidelityCurrency && watchAllFields.fidelityUnit && <span>For Each One(1) { watchAllFields.fidelityCurrency } spent, you'll give {watchAllFields.fidelityUnit} points</span>}

        <Spacing bottom={"sm"} />
        <Button type="submit">{buttonLabel}</Button>

      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
