import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Column, Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error, Select, MultiSelect } from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import countryList from "config/countryList";
import Map from "components/Map";
import languageList from "config/languageList.json";

const localesOptions = languageList.map((lng) => {
  return { value: lng.alpha2, label: lng.english };
});

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  selectedLocation,
}) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });
  const [showLocales, setShowLocales] = useState(false)

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = async (data) => {
    const locales = data?.locales ? data.locales.map((s) => s.value) : [];
    data.locales = [...locales];
    onSubmit(data);
  };

  function handleSelectMedia(url) {
    setValue("logo", url.location);
  }

  useEffect(() => {
    if (Array.isArray(defaultValues?.locales)) {
      const localeList = languageList
        .filter((l) => defaultValues.locales.indexOf(l.alpha2) > -1)
        .map((r) => {
          return { label: r.english, value: r.alpha2 };
        })
        .filter((e) => !!e.label && !!e.value);

      setValue("locales", localeList);
    }
    setShowLocales(true)
  }, [defaultValues, setValue]);



  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.place.name")}
          />
          {errors.name && <Error>Place Name is required</Error>}
        </Row>
        <Row>
          <Column>
            <Input
              {...register("email", { required: true })}
              placeholder={t("form.email")}
              type="email"
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email && <Error>Email Address is required</Error>}
          </Column>
          <Column>
            <Input {...register("phone")} placeholder={t("form.phone")} />
          </Column>
        </Row>
        <Row>
          <Column>
            <Input
              {...register("logo")}
              placeholder={t("form.logo")}
              disabled={!selectedLocation}
            />
            {selectedLocation && (
              <ModalGallery onClick={handleSelectMedia} isAbsolute />
            )}
          </Column>
          <Column>
            <Input {...register("website")} placeholder={t("form.website")} />
          </Column>
        </Row>

        <Row>
          <Column>
            <Input
              {...register("bookingurl")}
              placeholder={t("form.booking")}
            />
          </Column>
          <Column>
            <Input {...register("address")} placeholder={t("form.address")} />
          </Column>
        </Row>

        <Row>
          <Column>
            <Input {...register("city")} placeholder={t("form.city")} />
          </Column>
          <Column>
            <Input {...register("zipcode")} placeholder={t("form.zipcode")} />
          </Column>
        </Row>
        <Row>
          <Select {...register("country")} small>
            <option value={null} disabled>
              {t("form.country")}
            </option>
            {countryList.map((c, k) => (
              <option value={c} key={c + k}>
                {t(c)}
              </option>
            ))}
          </Select>
        </Row>
        <Spacing bottom={"sm"} />
        {localesOptions && showLocales &&  (
          <Controller
            name="locales"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={localesOptions}
                placeholder={t("locales")}
                {...field}
              />
            )}
          />
        )}

        <Spacing bottom={"lg"} />
        {defaultValues?.loc && defaultValues?.loc[0] ? (
          <Map
            latitude={String(defaultValues.loc[1])}
            longitude={String(defaultValues.loc[0])}
          />
        ) : null}

        <Spacing bottom={"sm"} />
        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
