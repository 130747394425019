import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row, Column } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Select } from "components/Form";
import { Title, Text, TextAlert } from "components/Typography";
import useUser from "hooks/useUser";
import { format } from "date-fns"


const fidelityStatuses = ["pending", "accepted", "rejected"];


export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { user } = useUser((state) => state.user);
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => { 
    Object.assign(defaultValues, {
      checkIn: format(new Date(defaultValues.checkIn),'yyyy-MM-dd'),
      checkOut: format(new Date(defaultValues.checkOut), 'yyyy-MM-dd') 
    })
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
     
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    onSubmit({ ...data});
  };

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Text>{t("form.fidelity.hotelName")}</Text>
          <Input
            {...register("hotelName")}
            placeholder={t("form.fidelity.hotelName")}
            type="text"
            disabled
          />
        </Row>
        <Row>
          <Column>
            <Text>{t("form.fidelity.invoiceNumber")}</Text>
            <Input
              {...register("invoiceNumber")}
              placeholder={t("form.fidelity.invoiceNumber")}
              type="text"
              disabled
            />
          </Column>
          <Column>
            <Text>{t("form.fidelity.averagePrice")}</Text>
            <Input
              {...register("averagePrice")}
              placeholder={t("form.fidelity.averagePrice")}
              type="number"
              disabled
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Text>{t("form.fidelity.checkIn")}</Text>
            <Input
              {...register('checkIn')}
              placeholder={t("form.fidelity.checkIn")}
              type='date'
              disabled
            />
          </Column>
          <Column>
            <Text>{t("form.fidelity.checkOut")}</Text>
            <Input
              {...register("checkOut")}
              placeholder={t("form.fidelity.checkOut")}
              type='date'
              disabled
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Text>{t("form.fidelity.points")}</Text>
            <Input
              {...register("points")}
              placeholder={t("form.fidelity.points")}
              type="number"
              disabled
            />
          </Column>
          <Column>
            <Text>{t("form.fidelity.transactionType")}</Text>
            <Input
              {...register("transactionType")}
              placeholder={t("form.fidelity.transactionType")}
              type="text"
              disabled
            />
          </Column>
        </Row>
        <Spacing bottom={"xl"} />
        <Spacing bottom={"xl"} />
        <Spacing bottom={"xl"} />
        <Row>
          <Title>{ t('update.status')}</Title>
          {defaultValues?.reviewers?.length === 1 && <TextAlert color="red">{t("form.fidelity.reviewersmessage")}</TextAlert>}
          {fidelityStatuses && fidelityStatuses[0] && (
            <Select
              {...register("status")}
              placeholder={t("form.fidelity.status")}
              small
              disabled={defaultValues?.reviewers?.includes(user?._id) || defaultValues?.status === "accepted" || defaultValues?.status === "rejected"}
            >
              <option disabled>{t("form.fidelity.status")}</option>

              {fidelityStatuses.map((c, k) => (
                <option value={c} key={c + k}>
                  {t(c)}
                </option>
              ))}
            </Select>
          )}
        </Row>
        <Spacing bottom={"sm"} />
       {(defaultValues?.status === "pending" && !defaultValues?.reviewers?.includes(user?._id)) && <Button type="submit">{buttonLabel}</Button>}
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
