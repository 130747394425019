import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import Layout from "components/Layout";
import TitleWithSub from "components/TitleWithSub";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import YoutubeEmbed from "components/YoutubeEmbed";
import { Spacing } from "components/Layout/Spacing";
import { Button } from "components/Form";
import { FixedBottom } from "components/Layout/Styles";
import { ContentContainer } from "components/Layout/Styles";
import ChartGuestSatisfaction from "./ChartGuestSatisfaction";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import LottieNoStats from './LottieNoStats'
import { GET_REVIEWS_STATS } from "config/queries";
import { getReviewsStats } from "./services";

export default function App() {
  const { t } = useTranslation();
  const [hideLayout, setHideLayout] = useState(false);
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);
  const { data: reviewStats } = useQuery(
    GET_REVIEWS_STATS + selectedLocation,
    () =>
      getReviewsStats({
        placeId: selectedLocation,
        accessToken: access.token,
      })
  );

  function formatGetReviewStats() {
    if (!reviewStats || !reviewStats[0] || !reviewStats[0]._id) {
      return null;
    }

    let copyData = { ...reviewStats[0] };
    delete copyData._id;

    let finalArray = [];

    for (const [key, value] of Object.entries(copyData)) {
      finalArray.push({
        subject: t(key),
        value: value * 20, //value is 0 to 5, need to convert it from 0 to 100
      });
    }

    return finalArray;
  }

  return (
    <Layout hideLeft={hideLayout} hideRight={hideLayout}>
      <Tabs style={{ padding: "20px" }}>
        <TabList>
          <Tab>{t("stats")}</Tab>
          <Tab>{t("help")}</Tab>
        </TabList>

        <TabPanel>
          {" "}
          <div style={{ background: "white", width: "100%", height: "100vh" }}>
            <Spacing top="md" />
            <TitleWithSub
              title={t('title.guestSatisfaction')}
              sub={t('subtitle.guestSatisfaction')}
            />
            {
              formatGetReviewStats() ?  <ChartGuestSatisfaction data={formatGetReviewStats()} /> : <LottieNoStats />
            }
           

            <Spacing top="md" />
          
          </div>
        </TabPanel>
        <TabPanel>
          <YoutubeEmbed embedId="ScMzIvxBSi4" />
        </TabPanel>
      </Tabs>

      <ContentContainer>
        <FixedBottom>
          <Button onClick={() => setHideLayout(!hideLayout)}>
            {!hideLayout ? t("present") : t("back")}
          </Button>
        </FixedBottom>
      </ContentContainer>
    </Layout>
  );
}
