const theme = {
    maxsize:['150px','250px'],
    font: {
      body: "Segoe UI Historic, Segoe UI, Helvetica, Arial, system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', sans-serif",
      title: 'HussarBold',
        weight: {
        light: '300',
        normal: '400',
        semibold:'500',
        bold: '600',
        bolder:'800'
      },
      size: {
        tiny: '11px',
        xxs: '13px',
        xs: '14px',
        sm: '16px',
        md: '18px',
        lg: '20px',
        xl: '34px',
  
      },
    },
  colors: {
    light: {
      main: "#f5f6f7",
      sidebar: "#161a23",
      secondary:"rgba(255,255,255,.93)",
      text: 'rgba(0, 0, 0, 0.87)', 
      contrastedText: 'rgba(255, 255, 255, 0.77)',
      border:'rgba(0, 0, 0, 0.2)'
      },
      black: '#000000',
      white: '#fff',
      success: '#10B981',
      danger: '#ff3737',
      blue: '#2B60C2',
      orange: 'papayawhip',
      violet: 'palevioletred',
      grey: 'rgba(247,247,247,.27)',
      gray: 'rgb(211,211,211, .47)',
      yellow:'#ffc107'
        
    },
    shadows: {
      sm: '2px 1px 20px 1px rgb(0 0 0 / 6%)',
      md: '11px -9px 20px 20px rgb(0 0 0 / 6%)',
      lg: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
      xl: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
      inset: '0px 1px 9px 0px rgb(0 0 0 / 10%), 0px 0px 0px 0px rgb(0 0 0 / 10%) inset'
    },
    screen: {
      xs: '540px',
      sm: '640px',
      md: '1007px',
      lg: '1100px',
      xl: '1230px',
      xxl :'2560px'
    },
    device:{
        mobileS: `(min-width: 320px)`,
        mobileM: `(min-width: 375px`,
        mobileL: `(min-width: 425px)`,
        tablet: `(min-width: 768px)`,
        laptop: `(min-width: 1024px)`,
        laptopL: `(min-width: 1440px)`,
        desktop: `(min-width: 2560px)`,
        desktopL: `(min-width: 2560px)`
      },
    spacing: {
      none: 0,
      xxs: '5px',
      xs: '10px',
      sm: '20px',
      md: '30px',
      lg: '40px',
      xl: '60px',
    },
    radius: {
      sm: '3px',
      md: '6px',
      lg: '12px',
    },
    zIndex: {
      xs: 10,
      sm: 20,
      md: 30,
      lg: 40,
      xl: 50,
    },
  };
  
  export default theme
  