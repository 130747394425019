import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import { getEmailQueue } from "./services";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import YoutubeEmbed from "components/YoutubeEmbed";
import List from "components/List";
import { GET_EMAIL_QUEUES } from "config/queries";

export default function App() {
  const [page, setPage] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [newOverlayVisible, setNewOverlayVisible] = useState(false);
  const { t } = useTranslation();

  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const campaignsList = useQuery(
    [GET_EMAIL_QUEUES + selectedLocation, page],
    () =>
      getEmailQueue({
        placeId: selectedLocation,
        accessToken: access.token,
        page,
      }),
    { keepPreviousData: true }
  );

  const onListClick = (item) => {
    setOverlayVisible(true);
  };

  function prepareItem(item) {
    let clonedItem = { ...item };
    clonedItem.id = item._id;
    clonedItem.name = item.customer.email;
    clonedItem.tags = [
      item.status,
      `Scheduled: ${moment(item.date).calendar()}`,
      item.emailTemplate,
      moment(item.updatedAt).fromNow(),
     
    ];
    return clonedItem;
  }
  let tabs = [];

  if (campaignsList?.data?.results && campaignsList.data.results[0]) {
    tabs.push({
      title: "🏢 " + t("categories"),
      component: (
        <List
          totalPages={campaignsList.data.totalPages}
          currentPage={page}
          onChangePage={(number) => setPage(number)}
          items={campaignsList.data.results.map((item) => {
            return {
              ...prepareItem(item),
            };
          })}
          onClick={onListClick}
        />
      ),
    });
  }

  tabs.push({
    title: t("help"),
    component: <YoutubeEmbed embedId="ScMzIvxBSi4" />,
  });

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}
      </Tabs>
      <Modal visible={overlayVisible} setVisible={setOverlayVisible}></Modal>
      <Modal
        visible={newOverlayVisible}
        setVisible={setNewOverlayVisible}
      ></Modal>
    </Layout>
  );
}
