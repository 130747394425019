import {  fetchit } from 'lib'

import {
    API_PACKAGE
} from 'config/api'


export function createPackage(data, accessToken) {
    return fetchit(API_PACKAGE, data, 'POST', accessToken)
}

export function editPackage(data, packageId, accessToken) {
    return fetchit(API_PACKAGE+'/'+packageId, data, 'PATCH', accessToken)
}

export function getPackages({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_PACKAGE+'/?place='+placeId, null, 'GET', accessToken)
}

export function deletePackage(packageId, accessToken) {
    if(!packageId) return
    return fetchit(API_PACKAGE+'/'+packageId, null, 'DELETE', accessToken)
}
