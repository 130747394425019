import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { omit } from "lodash-es";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import { getOrders, editOrder } from "./services";
import removeNil from "lib/removeNil";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import YoutubeEmbed from "components/YoutubeEmbed";
import Form from "./Form";
import List from "components/List";
import { GET_ORDERS } from "config/queries";


export default function Orders() {
  const [tabIndex, setTabIndex] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const { t } = useTranslation();
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const orderList = useQuery(GET_ORDERS + selectedLocation, () =>
  getOrders({
    placeId: selectedLocation,
    accessToken: access.token,
  })
  );
  
  function formatOrders(orders) {
    const formatted = orders.map(order => {
      return {
        ...order,
        name: order.room,
        tags: [...order.updates.map(u=>u.status), moment(order.updatedAt).fromNow()]
    }})
    return formatted
  }

  const onEditMutation = useMutation(
    (data) => editOrder(data, currentOrder._id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_ORDERS + selectedLocation);
        queryClient.invalidateQueries(GET_ORDERS + selectedLocation+'pending');
        setResetForm(true);
        setOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onEdit = async (data) => {
    const cleanData = omit(data, ["place", "createdBy"]);

    let dataCopy = { ...cleanData };
    onEditMutation.mutate({
      ...removeNil(dataCopy),
    });
  };

  const onListClick = (item) => {
    if (!item) return
    setOverlayVisible(true);
    setCurrentOrder({...item});
  };

  let tabs = [];

  if (orderList?.data?.results && orderList.data.results[0]) {
    tabs.push({
      title: "🏢 " + t("orders"),
      component: (
        <List
          items={formatOrders(orderList.data.results)}
          onClick={onListClick}
        />
      ),
    });
  }

  tabs.push({
    title: t("help"),
    component:  <YoutubeEmbed embedId="ScMzIvxBSi4" />,
  });

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}

       
      </Tabs>
      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <Form
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          defaultValues={currentOrder}
        />
      </Modal>
   
    </Layout>
  );
}
