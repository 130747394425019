import { useQuery } from "react-query";
import useUser from "hooks/useUser";
import { Link } from "react-router-dom";
import useLocation from "hooks/useLocation";
import { getUncompletedOrders } from "routes/orders/services";
import LottieNoOrders from './LottieNoOrders'
import LottiePendingOrder from './LottiePendingOrder'
import { Button } from "components/Form";

import {
  GET_ORDERS,
} from "config/queries";

export default function Orders() {
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const orderList = useQuery(GET_ORDERS + selectedLocation+'pending', () =>
    getUncompletedOrders({
      placeId: selectedLocation,
      accessToken: access.token,
    }),
    {
      refetchInterval: 10*1000,//10 seconds
    }
  );
    

  return (
    <Link to='/orders'>
      {(orderList?.data?.results && orderList?.data?.results[0]) ? (
        <div style={{padding:0}}>
          <LottiePendingOrder />
          <Button>Pending Orders</Button>
        </div>
     
      ) : (
        <LottieNoOrders />
      )}
    </Link>
  );
}
