import {  fetchit } from 'lib'

import {
    API_SOCIAL_MARKETING
} from 'config/api'


export function createWidget(data, accessToken) {
    return fetchit(API_SOCIAL_MARKETING, data, 'POST', accessToken)
}

export function editWidget(data, widgetId, accessToken) {
    return fetchit(API_SOCIAL_MARKETING+'/'+widgetId, data, 'PATCH', accessToken)
}

export function getWidgets({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_SOCIAL_MARKETING+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteWidgets(widgetId, accessToken) {
    if(!widgetId) return
    return fetchit(API_SOCIAL_MARKETING+'/'+widgetId, null, 'DELETE', accessToken)
}