import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Card = ({
  title,
  subtitle,
  description,
  onClick,
  dark,
  marginBottom,
}) => {
  const { t } = useTranslation();
  return (
    <Container dark={dark} marginBottom={marginBottom}>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
      {description && <Description>{description}</Description>}
      {typeof onClick === "function" && (
        <ActionButton success>{t("form.solve")}</ActionButton>
      )}
    </Container>
  );
};

export default Card;

const Container = styled.div`
  padding: 10px;
  background-color: ${(p) => (p.dark ? "rgba(0,0,0,.06)" : p.theme.colors.white)};
  margin-bottom: ${(p) => (p.marginBottom ? "10px" : 0)}; 
`;

const Title = styled.h3`
  margin: 0;
  color: ${(p) => p.theme.colors.light.text};
  font-weight: 300;
`;
const Subtitle = styled.div`
  color: ${(p) => p.theme.colors.light.border};
  font-weight: 300;
  margin: 0;
`;
const Description = styled.p`
  color: ${(p) => p.theme.colors.light.text};
  font-weight: 300;
`;
const ActionButton = styled.button`
  margin: 0 5px 0 0;
  padding: 8px 14px;
  background: ${(props) =>
    props.success
      ? props.theme.colors.success
      : (props) =>
          props.danger
            ? props.theme.colors.danger
            : "rgba(155, 155, 155, 0.2)"};
  color: ${(props) => props.theme.colors.light.contrastedText};
  cursor: pointer;
  border: 1px solid #fff;
  outline: 0;
  font-weight: 300;
  width: 100%;
  :hover {
    opacity: 0.8;
  }
`;
