import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Toggle from "react-toggle";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import {Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error, TextArea } from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const [media, setMedia] = useState([]);
  const [document, setDocument] = useState([]);
  const [passiveMode, setPassiveMode] = useState(false);
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;
      const documentIds =
      document && document[0] && document[0]._id ? document.map((m) => m._id) : null;
    delete data.tags

    onSubmit({ ...data, media: mediaIds, document: documentIds, passive: passiveMode });
  };

  const handleGallery = (data) => {
    setMedia(data);
  };

  
  const handleDocuments = (data) => {
    setDocument(data);
  };

  useEffect(() => {
    if (!!defaultValues?.media) {
      setMedia(defaultValues.media);
    }
    if (!!defaultValues?.document) {
      setDocument(defaultValues.document);
    }

    setPassiveMode(!!defaultValues?.passive);

  }, [defaultValues]);

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
      <Row>
         
         <Toggle
           checked={passiveMode}
           id="passive-mode"
           onChange={() => setPassiveMode(!passiveMode)}
         />
         <label htmlFor="passive-mode">{t("Screensaver")}</label>
      
        </Row>
        <Spacing bottom={"lg"} />
        {
          passiveMode && <Row>
          
              <label htmlFor="dates">{t("Broadcasting dates")}</label>
          <Input
                {...register("fromDate")}
                placeholder={t("From Date Time")}
                type="datetime-local"
            />
             <Input
                {...register("toDate")}
                placeholder={t("To Date Time")}
                type="datetime-local"
              />
        
          </Row>
        
        }
       
        <Spacing bottom={"lg"} />
       
          {
            passiveMode &&   <Row><label>Slider delay time in milliseconds (1000 = 1 second)</label>
            <Input
              {...register("delay")}
              placeholder={t("slider delay time in Milliseconds: eg 3000")}
              type="number"
              defaultValue={"5000"}
              min="3000"
            />
          
          </Row>
          }
      
        <Spacing bottom={"lg"} />
        <Row>
       
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.document.name")}
          />
          {errors.name && <Error>Package Name is required</Error>}
        </Row>
        <Row>
          <TextArea
            {...register("description")}
            aria-invalid={errors.description ? "true" : "false"}
            placeholder={t("form.document.description")}
          />
          
        </Row>

   

      
        <Spacing bottom={"sm"} />

        <Spacing bottom={"lg"} />
        <label>Medias   </label>
        <ModalGallery isMany onClick={handleGallery} />
          <Gallery medias={media} small />
       

      
        <Spacing bottom={"lg"} />
        <label>Documents</label>
        <ModalGallery isMany onClick={handleDocuments} />
        <Gallery medias={document} small />
        

        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
