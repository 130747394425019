import { useState, useEffect, useMemo } from "react";
import { DayPicker } from "react-day-picker";
import dateToDayMonthYear from "lib/dateToDayMonthYear";
import makeHoursBucketsByDay from "lib/makeHoursBucketsByDay";
import floatHourToDuration from "lib/floatHourToDuration";
import { Spacing } from "components/Layout/Spacing";
import groupByKey from "lib/groupByKey";
import { Row, Column } from "components/Layout/Styles";
import { Title } from "components/Typography";
import {
  Container,
  EventContainer,
  TimeButton,
  ButtonContainer,
  Badge,
  SpotContainer,
  SpotItem,
} from "./Style";
import "react-day-picker/dist/style.css";

export default function Calendar({
  selectedDate,
  setSelectedDate,
  openingHours,
  onNew,
  onEdit,
  bookedSpots,
  title,
}) {
  const [hoursBlock, setHoursBlock] = useState([]);
  const bookedSpotsByKey = useMemo(() => {
    if (bookedSpots) {
      const normalizeBookedSpots = bookedSpots.map((b) => {
        return { ...b, bucket: Math.round((b.startTime - 0.1) / 60) * 60 };
      });
      return groupByKey(normalizeBookedSpots, "bucket");
    }
  }, [bookedSpots]);

  useEffect(() => {
    if (selectedDate && openingHours) {
      setHoursBlock(makeHoursBucketsByDay(openingHours, selectedDate.getDay()));
    }
  }, [selectedDate, openingHours]);

  function handleSelected(date) {
    setSelectedDate(date);
  }

  function bookedSpotsByHour(hour) {
    if (!hour || !bookedSpotsByKey) return;
    return bookedSpotsByKey[
      Object.keys(bookedSpotsByKey)?.filter(
        (key) => key.toString() === (hour * 60)?.toString()
      )[0]
    ];
  }

  return (
    <Row>
      <Column>
        <Container>
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={handleSelected}
          />
          <Spacing bottom="xl" />
        </Container>
      </Column>

      <Column style={{height:'100vh', overflowY: "auto"}}>
      <Title dark style={{textTransform: "capitalize"}}>	🌄 { title }</Title>
        {hoursBlock.map((hour, k) => (
          <ButtonContainer
            key={hour + k}
            onClick={() =>
              onNew({
                hour: hour * 60,
                ...dateToDayMonthYear(selectedDate),
              })
            }
          >
            {bookedSpotsByHour(hour)?.length && (
              <Badge>{bookedSpotsByHour(hour).length}</Badge>
            )}

            <TimeButton>{floatHourToDuration(hour).text}</TimeButton>
          </ButtonContainer>
        ))}

        {bookedSpotsByKey && (
          <EventContainer style={{overflow:"auto"}}>
            {Object.keys(bookedSpotsByKey).map((key, index) => {
              return (
                <SpotContainer key={index} >
                  <Title dark>
                    {floatHourToDuration(key / 60).text} -{" "}
                    {floatHourToDuration(key / 60 + 1).text}
                  </Title>
                  {bookedSpotsByKey[key].map((spot, k) => (
                    <RenderSpot {...spot} key={spot.room + k}  onClick={() => onEdit(spot) } />
                  ))}
                </SpotContainer>
              );
            })}
          </EventContainer>
        )}
      </Column>
    </Row>
  );
}

const RenderSpot = ({ endTime, startTime, room, onClick }) => (
  <SpotItem onClick={onClick}>
    <p>
      {floatHourToDuration(startTime / 60).text} to{" "}
      {floatHourToDuration(endTime / 60).text}, room {room} 🔎
    </p>
  </SpotItem>
);
