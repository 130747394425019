import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Settings from './Settings'
import Redeem from './Redeem'

export default function HomeRoutes() {
  return (
    <Routes>
      <Route path="/activities" element={<Home />} />
      <Route path="/settings" element={<Settings />} />
      <Route path="/Redeem" element={<Redeem />} />
    </Routes>
  );
}