import * as React from "react"

const UpOuiLogo = (props) => (
    <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 600 400"
  
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st0{fill:#fff}"}</style>
    <path
      className="st0"
      d="M506.5 370.7H431V224.5h96.3c27.3 0 49.5 22.3 49.5 49.6 0 27.4-22.2 49.6-49.5 49.6h-20.7v47zM338.1 370.7c-40.2 0-72.9-32.8-72.9-73.1v-73.1H330v73.1c0 4.5 3.6 8.1 8.1 8.1s8.1-3.6 8.1-8.1v-73.1H411v73.1c-.1 40.4-32.8 73.1-72.9 73.1z"
    />
  </svg>

)

export default UpOuiLogo
