import { useState } from "react";
import { Input, Button } from "components/Form";
import { Spacing } from "components/Layout/Spacing";
import ModalGallery from "routes/media/ModalGallery";
import { WIDGET_BASE_URL } from "config/api";

const ImagePlayerWidget = ({ onChange }) => {
  const [medias, setMedias] = useState([]);

  return (
    <>
      <Input
        placeholder="image url... "
        name="media"
        defaultValue={medias}
        onChange={(e) => setMedias(e.target.value)}
      />

      <Spacing bottom={"lg"} />
      <ModalGallery onClick={(m) => setMedias(m.location)} isMany={false} />
      <Spacing bottom={"lg"} />
      <Button
        onClick={() => onChange(`${WIDGET_BASE_URL}/carousel?url=${medias}`)}
      >
        OK
      </Button>
      <Spacing bottom={"lg"} />
    </>
  );
};

export default ImagePlayerWidget;
