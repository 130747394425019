import { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import {
  getSchedules,
  deleteSchedules,
  createSchedule,
  editSchedule,
} from "./services";
import {
  getDepartmentById
} from '../departments/services'
import { getProducts } from '../products/services'
import { getHotelById } from "../locations/services";
import removeNil from "lib/removeNil";
import dateToDayMonthYear from "lib/dateToDayMonthYear";

import Modal from "components/Layout/Modal";
import Form from "./Form";



import Calendar from "./Calendar";
import { GET_SCHEDULES, GET_DEPARTMENTS, GET_PRODUCTS } from "config/queries";



export default function Home() {
  let { departmentId } = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [newOverlayVisible, setNewOverlayVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const { t } = useTranslation();
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const dateToObject = useMemo(
    () => dateToDayMonthYear(selectedDate),
    [selectedDate]
  );

  const selectedLocationDetails = useQuery(selectedLocation, () =>
    getHotelById({
      hotelId: selectedLocation,
      accessToken: access.token,
    })
  );

  const selectedDepartment = useQuery(GET_DEPARTMENTS + departmentId, () =>
    getDepartmentById({
      departmentId: departmentId,
      accessToken: access.token,
    })
  );

  
  const selectedProducts = useQuery(GET_PRODUCTS + departmentId, () =>
    getProducts({
      placeId: selectedLocation,
      department: departmentId,
      accessToken: access.token,
      limit:20
    })
  );


  const scheduleList = useQuery(GET_SCHEDULES + departmentId+JSON.stringify(dateToObject), () =>
    getSchedules({
      departmentId,
      year: dateToObject?.year,
      month: dateToObject?.month,
      day: dateToObject?.day,
      accessToken: access.token,
    })
  );

  const onNewMutation = useMutation(
    (data) => createSchedule(data, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_SCHEDULES + departmentId+JSON.stringify(dateToObject));
        setResetForm(true);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onEditMutation = useMutation(
    (data) => editSchedule(data, currentSchedule._id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_SCHEDULES + departmentId+JSON.stringify(dateToObject));
        setResetForm(true);
        setOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onDeleteMutation = useMutation(
    (departmentId) => deleteSchedules(departmentId, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_SCHEDULES + departmentId + JSON.stringify(dateToObject));
        setOverlayVisible(false)
      },
      onError: (err) => {
        toast.error(t("update.error"));
      },
    }
  );

  const onNew = async (data) => {
    let dataCopy = { ...data };
    onNewMutation.mutate({
      ...removeNil(dataCopy),
      department: departmentId,
    });

    setNewOverlayVisible(false);
  };

  const onEdit = async (data) => {
    let dataCopy = { ...data };
    onEditMutation.mutate({
      ...removeNil(dataCopy),
    });
  };

  const onDelete = (scheduleId) => {
    onDeleteMutation.mutate(scheduleId);
  };

  function handleNew(schedule) {
    setCurrentSchedule(schedule)
    setNewOverlayVisible(true)
  }

  function handleEdit(schedule) {
    setCurrentSchedule(schedule)
    setOverlayVisible(true)
  }


  return (
    <>
      <Calendar
        title={selectedDepartment?.data?.name}
        items={scheduleList?.data?.results}
        onDelete={onDelete}
        onNew={handleNew}
        onEdit={handleEdit}
        setSelectedDate={setSelectedDate}
        selectedDate={selectedDate}
        openingHours={selectedDepartment?.data?.openingHours}
        bookedSpots={scheduleList?.data?.results}
      />

      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <Form
          onSubmit={onEdit}
          onDelete={onDelete}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          members={selectedLocationDetails?.data?.staff}
          defaultValues={currentSchedule}
          products= { selectedProducts?.data?.results}
        />
      </Modal>

      <Modal visible={newOverlayVisible} setVisible={setNewOverlayVisible}>
        <Form
          onSubmit={onNew}
          buttonLabel={t("form.save")}
          resetForm={resetForm}
          defaultValues={currentSchedule}
          products= { selectedProducts?.data?.results}
        />
      </Modal>
    </>
  );
}
