import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { omit } from "lodash-es";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import {
  getProducts,
  deleteProduct,
  createProduct,
  editProduct,
} from "./services";
import { getDepartments } from "routes/departments/services";
import { getCategories } from "routes/categories/services";
import { getHotelById } from "../locations/services";
import removeNil from "lib/removeNil";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import YoutubeEmbed from "components/YoutubeEmbed";
import Form from "./Form";
import DictionaryForm from "./DictionaryForm";
import Filter from "./Filter";
import List from "components/List";
import { Button } from "components/Form";
import { FixedBottom } from "components/Layout/Styles";
import { GET_PRODUCTS, GET_DEPARTMENTS, GET_CATEGORIES } from "config/queries";

export default function App() {
  const [selectedCategory, setSelectedCategory] = useState();
  const [page, setPage] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [newOverlayVisible, setNewOverlayVisible] = useState(false);
  const [dictionaryVisible, setDictionaryVisible] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const { t } = useTranslation();
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const productList = useQuery(
    [GET_PRODUCTS + selectedLocation, page, selectedCategory],
    () => {
      let filter = {
        placeId: selectedLocation,
        accessToken: access.token,
        page,
      };

      if (selectedCategory && selectedCategory.length > 10) {
        filter.categories = selectedCategory;
      } else {
        delete filter.categories;
      }

      return getProducts(filter);
    },
    { keepPreviousData: true }
  );

  const selectedLocationDetails = useQuery(selectedLocation, () =>
    getHotelById({
      hotelId: selectedLocation,
      accessToken: access.token,
    })
  );

  const categoryList = useQuery(GET_CATEGORIES + selectedLocation, () =>
    getCategories({
      placeId: selectedLocation,
      accessToken: access.token,
      limit: 200,
    })
  );

  const departmentList = useQuery(GET_DEPARTMENTS + selectedLocation, () =>
    getDepartments({
      placeId: selectedLocation,
      accessToken: access.token,
    })
  );

  const onNewMutation = useMutation(
    (data) => createProduct(data, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_PRODUCTS + selectedLocation);
        setResetForm(true);
        setNewOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onEditMutation = useMutation(
    (data) => editProduct(data, currentProduct._id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_PRODUCTS + selectedLocation);
        setResetForm(true);
        setOverlayVisible(false);
        setDictionaryVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onDeleteMutation = useMutation(
    (productId) => deleteProduct(productId, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_PRODUCTS + selectedLocation);
      },
      onError: (err) => {
        toast.error(t("update.error"));
        console.error(err);
      },
    }
  );

  const onNew = async (data) => {
    let dataCopy = { ...data };
    onNewMutation.mutate({
      ...removeNil(dataCopy),
      place: selectedLocation,
    });
    setTabIndex(0);
  };

  const onEdit = async (data) => {
    const cleanData = omit(data, ["place", "createdBy", "slug"]);

    let dataCopy = { ...cleanData };
    onEditMutation.mutate({
      ...removeNil(dataCopy),
    });
  };

  const onDelete = (productId) => {
    onDeleteMutation.mutate(productId);
  };

  const onListClick = (item) => {
    if (!item) return;
    let { categories } = item;

    let formatCategories =
      Array.isArray(categories) && categoryList?.data?.results
        ? categoryList?.data?.results
            .filter((c) => categories.indexOf(c._id) > -1)
            .map((e) => {
              return { label: e.name, value: e._id };
            })
        : [];

    setOverlayVisible(true);
    setCurrentProduct({ ...item, categories: formatCategories });
  };

  const onDictionaryClick = (item) => {
    setDictionaryVisible(true);
    setCurrentProduct({
      _id: item._id,
      dictionary: item.dictionary,
    });
  };

  let tabs = [];

  if (productList?.data?.results && productList.data.results[0]) {
    tabs.push({
      title: "🏢 " + t("products"),
      component: (
        <List
          onDictionaryClick={onDictionaryClick}
          totalPages={productList.data.totalPages}
          onDeleteBtn={onDelete}
          currentPage={page}
          onChangePage={(number) => setPage(number)}
          items={productList.data.results}
          onClick={onListClick}
        />
      ),
    });
  }

  tabs.push({
    title: t("help"),
    component: <YoutubeEmbed embedId="ScMzIvxBSi4" />,
  });

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
        <Filter
          categoryList={categoryList?.data?.results}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            setPage(0);
          }}
        />
        <Spacing bottom="md" />
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}

        <FixedBottom>
          <Button onClick={() => setNewOverlayVisible(true)}>
            {t("form.add")} {t("product")}
          </Button>
        </FixedBottom>
      </Tabs>
      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <Form
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          defaultValues={currentProduct}
          productList={productList?.data?.results}
          departmentList={departmentList?.data?.results}
          categoryList={categoryList?.data?.results}
        />
      </Modal>
      <Modal visible={newOverlayVisible} setVisible={setNewOverlayVisible}>
        <Form
          onSubmit={onNew}
          buttonLabel={t("form.save")}
          resetForm={resetForm}
          productList={productList?.data?.results}
          departmentList={departmentList?.data?.results}
          categoryList={categoryList?.data?.results}
        />
      </Modal>
      <Modal visible={dictionaryVisible} setVisible={setDictionaryVisible}>
        <DictionaryForm
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          languages={selectedLocationDetails?.data?.locales}
          defaultValues={currentProduct}
        />
      </Modal>
    </Layout>
  );
}
