import { fetchit } from 'lib'
import {
    API_AUTH_LOGIN,
    API_AUTH_LOGOUT,
    API_AUTH_REFRESH_TOKENS,
    API_AUTH_AUTHORIZE
} from 'config/api'


export function loginOrRegister(email, uniqueId) {
    return fetchit(API_AUTH_LOGIN, { email, uniqueId },'POST') 
}

export function refreshToken(refreshToken, uniqueId) {
    return fetchit(API_AUTH_REFRESH_TOKENS, { refreshToken, uniqueId },'POST')
}

export function authorize(email, uniqueId) {
    return fetchit(API_AUTH_AUTHORIZE, { email, uniqueId },'POST')
}

export function logout(refreshToken, uniqueId) {
    return fetchit(API_AUTH_LOGOUT, { refreshToken, uniqueId },'POST')
}