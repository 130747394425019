import { Player } from "@lottiefiles/react-lottie-player";

export default function HomeLine() {
  return <div style={styles.container}>


<Player
      autoplay
      loop
      src="https://assets4.lottiefiles.com/packages/lf20_jjui4rud.json"
      style={styles.icon}
    />
  </div>;
}

const styles = {
  container: {
    backgroundColor: "rgba(255,255,255,.9)",
    marginRight: "-9px",
    width: '100px',
    height: '44px',
    boxShadow: '2px 1px 20px 1px rgb(0 0 0 / 6%)',
    borderLeft:'8px solid rgba(0,0,0,.05)'
  },
  icon: {
    height: "45px",
    width: "100px",
 
  }
}