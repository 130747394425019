import intervalToDuration from "date-fns/intervalToDuration";

/**
 * Converts 2.5 (hour) to {hour:2, minutes:30}
 * @param {*} hour
 *
 */
 export default function floatHourToDuration(hour) {
    const interval = intervalToDuration({
      start: 0,
      end: hour * 60 * 60 * 1000,
    });
    return {
      ...interval,
      text: `${interval.hours}:${interval.minutes || "00"}`,
    };
  }