import React, { Fragment } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "components/ProtectedRoute";
import AuthRoutes, { path as authPath } from "./auth";
import NotFoundRoutes, { path as notFoundPath } from "./404";
import PublicRoutes, { path as publicPath } from "./public";
//🔒 protected routes
import DashboardRoutes, { path as dashboardPath } from "./dashboard";
import LocationsRoutes, { path as locationsPath } from "./locations";
import DepartmentsRoutes, { path as departmentPath } from "./departments";
import RoomRoutes, { path as roomPath } from "./rooms";
import MediaRoutes, { path as mediaPath } from "./media";
import CategoryRoutes, { path as categoryPath } from "./categories";
import ProductRoutes, { path as productPath } from "./products";
import OrderRoutes, { path as orderPath } from './orders'
import ReservationRoutes, { path as reservationPath } from './reservations'
import TemplateRoutes, { path as templatePath } from './emailMarketing'
import IntergrationRoutes, { path as integrationPath } from './integrations'
import PackageRoutes, { path as packagePath } from './packages'
import CalendarRoutes, { path as calendarPath} from './calendar'
import SocialMarketingRoutes, { path as socialMarketingPath } from './socialMarketing'
import StaffRoutes, { path as staffPath} from './staff'
import FidelityRoutes, { path as fidelityPath } from "./fidelity";
import KioskRoutes, { path as kioskPath } from './kiosks'
import DocumentRoutes, { path as documentPath } from './documents'

export default function RoutesComponent() {
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
        theme="colored"
      />
      <BrowserRouter>
        <Fragment>
          <Routes>
            <Route exact path={authPath} element={<AuthRoutes />} />
            <Route exact path={publicPath} element={<PublicRoutes />} />
            <Route element={<ProtectedRoute />}>
              {/**🔒 Protected routes */}
              <Route exact path={dashboardPath} element={<DashboardRoutes />} />
              <Route exact path={locationsPath} element={<LocationsRoutes />} />
              <Route exact path={departmentPath} element={<DepartmentsRoutes />}/>
              <Route exact path={roomPath} element={<RoomRoutes />} />
              <Route exact path={documentPath} element={<DocumentRoutes />} />
              <Route exact path={mediaPath} element={<MediaRoutes />} />
              <Route exact path={categoryPath} element={<CategoryRoutes />} />
              <Route exact path={productPath} element={<ProductRoutes />} />
              <Route exact path={orderPath} element={<OrderRoutes />} />
              <Route exact path={reservationPath} element={<ReservationRoutes />} />
              <Route exact path={templatePath} element={<TemplateRoutes />} />
              <Route exact path={integrationPath} element={<IntergrationRoutes />} />
              <Route exact path={packagePath} element={<PackageRoutes />} />
              <Route exact path={calendarPath} element={<CalendarRoutes />} />
              <Route exact path={socialMarketingPath} element={<SocialMarketingRoutes />} />
              <Route exact path={staffPath} element={<StaffRoutes />} />
              <Route exact path={fidelityPath} element={<FidelityRoutes />} />
              <Route exact path={kioskPath} element={<KioskRoutes />} />
              {/**🔒 End of Protected routes */}
            </Route>
            <Route path={notFoundPath} element={<NotFoundRoutes />} />
          </Routes>
        </Fragment>
      </BrowserRouter>
    </>
  );
}
