import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Column, Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Error,
  MultiSelect,
  TextArea,
  Select,
} from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from 'routes/media/Gallery'

import {
  roomTypes,
  roomShapes,
  roomFacilities,
  roomAmenities,
} from "config/rooms";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const [media, setMedia] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const amenities = data?.amenities ? data.amenities.map((s) => s.value) : [];
    const facilities = data?.facilities
      ? data.facilities.map((s) => s.value)
      : [];
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;
    onSubmit({ ...data, amenities, facilities, media: mediaIds });
  };

  const handleGallery = (data) => {
    setMedia(data);
  };

  /** */
  const facilities_options =
    roomFacilities && roomFacilities[0]
      ? roomFacilities.map((f) => {
          return { value: f, label: t(f) };
        })
      : null;

  const amenities_options =
    roomAmenities && roomAmenities[0]
      ? roomAmenities.map((f) => {
          return { value: f, label: t(f) };
        })
      : null;

  useEffect(() => {
    if (defaultValues?.media) {
      setMedia(defaultValues.media)
    }
   
  },[defaultValues])

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.room.name")}
          />
          {errors.name && <Error>Room Name is required</Error>}
        </Row>
        <Row>
          <TextArea
            {...register("description")}
            aria-invalid={errors.description ? "true" : "false"}
            placeholder={t("form.room.description")}
          />
         
        </Row>
        <Row>
          <Input
            {...register("dimensions")}
            placeholder={t("form.room.dimensions")}
          />
        </Row>
        <Row>
          <Column>
            <Input
              type="number"
              {...register("adult_capacity")}
              placeholder={t("form.adult.capacity")}
            />
          </Column>
          <Column>
            <Input
              type="number"
              {...register("children_capacity")}
              placeholder={t("form.children.capacity")}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Select
              {...register("type")}
              placeholder={t("form.room.type")}
              small
            >
              <option value={null} disabled>
                {t("form.room.type")}
              </option>
              {roomTypes.map((c, k) => (
                <option value={c} key={c + k}>
                  {c}
                </option>
              ))}
            </Select>
          </Column>

          <Column>
            <Select
              {...register("shape")}
              placeholder={t("form.room.shape")}
              small
            >
              <option value={null} disabled>
                {t("form.room.shape")}
              </option>
              {roomShapes.map((c, k) => (
                <option value={c} key={c + k}>
                  {t(c)}
                </option>
              ))}
            </Select>
          </Column>
        </Row>
        <Spacing bottom={"sm"} />

        {facilities_options && (
          <Controller
            name="facilities"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={facilities_options}
                placeholder={t("facilities")}
                {...field}
              />
            )}
          />
        )}
        {amenities_options && (
          <Controller
            name="amenities"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={amenities_options}
                placeholder={t("amenities")}
                {...field}
              />
            )}
          />
        )}

        <Spacing bottom={"lg"} />
        <ModalGallery isMany onClick={handleGallery} />
        <Gallery medias={media} small/>
        <Button type="submit">{buttonLabel}</Button>
      
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
