import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Column, Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Error,
  MultiSelect,
  Select,
} from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from 'routes/media/Gallery'
import countryList from "config/countryList";
import {
  widgetsComponents,
  noTagsArray,
  imagesWidgets
} from "config/social";
import Toggle from "react-toggle";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const [showConditions, setShowConditions] = useState(false);
  const [checkedCounter, setCheckedCounter] = useState(false);
  const [media, setMedia] = useState([]);
  const [showTags, setShowTags] = useState(false)
  const [widgetsComponent, setWidgetComponent] = useState(widgetsComponents[0])
  
  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });


  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;
      data?.conditions && Object.assign(data.conditions, {counterTag: checkedCounter })
      onSubmit({ ...data, media: mediaIds});
  };

  const handleGallery = (data) => {
    setMedia(data);
  };

  const socialArea_options =
    countryList && countryList[0]
      && countryList.map((f) => {
          return { value: f, label: t(f) };
        })

  useEffect(() => {
    if (defaultValues?.media) {
      setMedia(defaultValues.media)
    }
  },[defaultValues])

  useEffect(() => {
    // display countries
    if (defaultValues?.conditions && Array.isArray(defaultValues?.conditions?.countries) && defaultValues?.conditions?.countries.length > 0) {
      const countries = countryList
        .filter((l) => defaultValues.conditions?.countries.indexOf(l) > -1)
        .map((r) => {
          return { label: r, value: r };
        })
        .filter((e) => !!e.label && !!e.value);

      setValue("conditions.countries", countries);
    }
    defaultValues?.conditions?.counterTag && setCheckedCounter(defaultValues?.conditions?.counterTag)
  }, [defaultValues, setValue]);

  useEffect(() => {
    // Check widget if have tags
    noTagsArray.includes(widgetsComponent) ? setShowTags(false) : setShowTags(true)
  }, [widgetsComponent])

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.social.name")}
          />
          {errors.name && <Error>Widget name is required</Error>}
        </Row>
        <Row>
          <Select
              {...register("component")}
              placeholder={t("form.social.component")}
              small
              onChange={(e) => setWidgetComponent(e.target.value)}
            >
              <option value={null} disabled>
                {t("form.social.component")}
              </option>
              {widgetsComponents.map((c, k) => (
                <option value={c} key={c + k} >
                  {t(c)}
                </option>
              ))}
            </Select>
         
        </Row>
        <Spacing bottom={"sm"} />
        <Row>
          <Input
            {...register("title", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.social.title")}
          />
          {errors.name && <Error>Widget title is required</Error>}
        </Row>
        <Row>
          <Input
            {...register("subtitle")}
            aria-invalid={errors.description ? "true" : "false"}
            placeholder={t("form.social.subtitle")}
          />
         
        </Row>
        {showTags &&
        <>
          <Row>
            <Input
              {...register("tagOne")}
              placeholder={t("form.social.tagOne")}
            />
          </Row>
          <Row>
            <Input
              {...register("tagTwo")}
              placeholder={t("form.social.tagTwo")}
            />
          </Row>
          <Row>
            <Input
              {...register("tagThree")}
              placeholder={t("form.social.tagThree")}
            />
          </Row>
          </>
         }
        <Spacing bottom="sm" />
        <Row>
          <Input
            {...register("bookingUrl")}
            placeholder={t("form.social.bookingUrl")}
          />
        </Row>
        <Spacing bottom={"sm"} />
        <Button onClick={(e) => {e.preventDefault() 
        setShowConditions(!showConditions)}}>{t("form.social.conditionsButton")}</Button>
        <Spacing bottom={"sm"} />
        {showConditions && <>
          <Row>
            <Column>
              <Input
                {...register("conditions.startDate")}
                placeholder={t("form.social.satrtdate")}
                type='datetime-local'
                />
            </Column>
            <Column>
              <Input
                {...register("conditions.endDate")}
                placeholder={t("form.social.enddate")}
                type='datetime-local'
              />
            </Column>
          </Row>
          <Row>
          {socialArea_options && (
            <Controller
              name="conditions.countries"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  control={control}
                  isMulti
                  options={socialArea_options}
                  placeholder={t("form.social.conditionsCountries")}
                  {...field}
                />
              )}
            />
          )}
          </Row>
          <Row>
            <Column>
              <Input
                {...register("conditions.spendTime")}
                placeholder={t("form.social.timemin")}
                type='number'
                />
            </Column>
            <Column>
              <Input
                {...register("conditions.pagesNumber")}
                placeholder={t("form.social.pagesnumber")}
                type='number'
              />
            </Column>
          </Row>

        <Spacing bottom="sm" />
          <Row>
            <Toggle
              value={checkedCounter.toString()}
              checked={checkedCounter}
              id="conditions.counterTag"
              name="conditions.counterTag"
              onChange={() => setCheckedCounter(!checkedCounter)}
            />
            <label htmlFor="conditions.counterTag">{t("form.label.counter")}</label>
        </Row> 
        </>}

        <Spacing bottom={"lg"} />
        {imagesWidgets.includes(widgetsComponent) && <ModalGallery isMany onClick={handleGallery} />}
        <Gallery medias={media} small/>
        <Button type="submit">{buttonLabel}</Button>
      
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
