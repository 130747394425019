import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useState } from "react";
import { useMutation } from "react-query";
import { v4 as uuidv4 } from "uuid";
import { loginOrRegister } from "./services";
import { AUTHORIZE_PATH, REDIRECT_PATH } from "config/paths";
import useUser from "hooks/useUser";
import useUuid from "hooks/useUuid";
import useLocation from 'hooks/useLocation'
import { Title, Text } from "components/Typography";
import { Button, Input } from "components/Form";
import { Spacing } from "components/Layout/Spacing";
import { Row, Column } from "components/Layout/Styles";
import { Container, LoginBox, Wrapper, RightSide, Error } from "./Style";
import LottieLogin from './LottieLogin'

export default function LoginComponent() {
  const user = useUser((state) => state.user);
  const deleteUser = useUser(state => state.deleteUser);
  const deleteSelected = useLocation(state=>state.deleteSelected)
  const uniqueId = useUuid((state) => state.uuid);
  const setUuid = useUuid((state) => state.setUuid);
  const [email, setEmail] = useState();
  const mutation = useMutation(() => loginOrRegister(email, uniqueId));

  useEffect(() => {
    deleteUser()
    deleteSelected()
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(!uniqueId) {
      setUuid(uuidv4());
    }
  },[uniqueId, setUuid])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!!email && !!uniqueId) {
      mutation.mutate();
    } else {
      alert('Errors')
    }
  };

  const handleEmailInput = (e) => setEmail(e.target.value, uniqueId);

  if (!user?.user && mutation.isSuccess) {
    return <Navigate to={`${AUTHORIZE_PATH}/${uniqueId}/${email}`} replace />;
  }

  if (!!user?.user?.email) {
    return <Navigate to={REDIRECT_PATH} replace />;
  }

  return (
    <Row>
      <Column>
        <Container>
          <LoginBox>
            <Title>Log in</Title>
            <form onSubmit={handleSubmit}>
              <Wrapper>
                <Input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  onChange={handleEmailInput}
                />
              </Wrapper>
              <Spacing bottom="sm" />
              <Wrapper>
                <Button type="submit" disabled={!email}>
                  {!mutation.isSuccess &&
                  !mutation.isError &&
                  !mutation.isLoading
                    ? "Continue"
                    : null}
                  {mutation.isSuccess ? "check your email inbox" : null}
                  {mutation.isError ? "Try again" : null}
                  {mutation.isLoading ? "Loading..." : null}
                </Button>
                <Spacing bottom="md" />
                <Text sm>By clicking continue, you agree to Casbah Tourism Development SARL <a href="https://concierge.fra1.digitaloceanspaces.com/26c50f31-1fcf-48f9-ab4e-d804409d7555.pdf">Terms of Service</a> and <a href="https://concierge.fra1.digitaloceanspaces.com/719b740e-c1fe-4d6d-a0ff-8b2e365e69f2.pdf"> Privacy Policy.</a></Text>
                <Spacing bottom="sm" />
                {mutation.isError ? <Error>❌🤓 {mutation.error.message}</Error> : null}
              </Wrapper>
            </form>
          </LoginBox>
        </Container>
      </Column>
      <Column>
        <RightSide alert={mutation.isError && !mutation.isSuccess}>
          <LottieLogin />
       </RightSide>
       
      </Column>
    </Row>
  );
}
