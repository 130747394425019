import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { omit } from "lodash-es";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import { getWidgets, deleteWidgets, createWidget, editWidget } from "./services";
import { getHotelById } from "../locations/services";
import removeNil from "lib/removeNil";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import YoutubeEmbed from "components/YoutubeEmbed";
import Form from "./Form";
import DictionaryForm from './DictionaryForm';
import List from "components/List";
import { Button } from "components/Form";
import { Column, FixedBottom, Row } from "components/Layout/Styles";
import { GET_WIDGETS } from "config/queries";
import defaultWidgets from "./defaultsWidgets"

export default function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [dictionaryVisible, setDictionaryVisible] = useState(false);
  const [newOverlayVisible, setNewOverlayVisible] = useState(false);
  const [currentWidget, setCurrentWidget] = useState(null);
  const { t } = useTranslation();
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const widgetList = useQuery(GET_WIDGETS + selectedLocation, () =>
    getWidgets({
      placeId: selectedLocation,
      accessToken: access.token,
    })
  );

  const selectedLocationDetails = useQuery(selectedLocation, () =>
    getHotelById({
      hotelId: selectedLocation,
      accessToken: access.token,
    })
  );

  const onNewMutation = useMutation((data) => createWidget(data, access.token), {
    onSuccess: () => {
      toast.success(t("update.success"));
      queryClient.invalidateQueries(GET_WIDGETS + selectedLocation);
      setResetForm(true);
      setNewOverlayVisible(false)
    },
    onError: () => {
      toast.error(t("update.error"));
    },
  });

  const onEditMutation = useMutation(
    (data) => editWidget(data, currentWidget._id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_WIDGETS + selectedLocation);
        setResetForm(true);
        setOverlayVisible(false);
        setDictionaryVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onDeleteMutation = useMutation(
    (widgetId) => deleteWidgets(widgetId, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_WIDGETS + selectedLocation);
      },
      onError: (err) => {
        toast.error(t("update.error"));
        console.error(err);
      },
    }
  );

  const onNew = async (data) => {
    let dataCopy = { ...data };
    const countries = data?.conditions?.countries.map((c) => c.value);
    dataCopy.conditions = {
      ...dataCopy?.conditions,
      countries,
    };

    onNewMutation.mutate({
      ...removeNil(dataCopy),
      place: selectedLocation,
    });
    setTabIndex(0);
  };

  const onEdit = async (data) => {
    const cleanData = omit(data, ["place", "createdBy", "slug"]);

    let dataCopy = { ...cleanData };
    const countries = data?.conditions?.countries.map((c) => c.value);
    dataCopy.conditions = {
      ...dataCopy?.conditions,
      countries,
    };
    
    onEditMutation.mutate({
      ...removeNil(dataCopy),
    });
  };

  const onDelete = (widgetId) => {
    onDeleteMutation.mutate(widgetId);
  };

  const onListClick = (item) => {
 
    setOverlayVisible(true);
    setCurrentWidget(item);
  };

  const onDictionaryClick = (item) => {
    setDictionaryVisible(true);
    setCurrentWidget({
      _id: item._id,
      dictionary: item.dictionary
    });
  };

  const handleDefaultWidgets = (e) => {
    e.preventDefault();
    defaultWidgets.forEach((widget) => {
      onNewMutation.mutate({
        ...removeNil(widget),
        place: selectedLocation,
      });
    })
  }


  let tabs = [];

  if (widgetList?.data?.results && widgetList.data.results[0]) {
    tabs.push({
      title: "🏢 " + t("widgets"),
      component: (
        <List
          items={widgetList.data.results}
          onDeleteBtn={onDelete}
          onDictionaryClick={onDictionaryClick}
          onClick={onListClick}
        />
      ),
    });
  }

  tabs.push({
    title: t("help"),
    component:  <YoutubeEmbed embedId="ScMzIvxBSi4" />,
  });

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}

        <FixedBottom>
          <Row>
            <Column>
              <Button onClick={() => setNewOverlayVisible(true)}>
                {t("form.add")} {t("widget")}
              </Button>
            </Column>
            <Column>
              <Button onClick={handleDefaultWidgets} secondary={defaultWidgets.length <= 0 }>
                {t("defaultwidgets")}
              </Button>
            </Column>
          </Row>
        </FixedBottom>
      
      </Tabs>
      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <Form
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          defaultValues={currentWidget}
        />
      </Modal>
      <Modal visible={newOverlayVisible} setVisible={setNewOverlayVisible}>
        <Form
          onSubmit={onNew}
          buttonLabel={t("form.save")}
          resetForm={resetForm}
        />
      </Modal>

      <Modal visible={dictionaryVisible} setVisible={setDictionaryVisible}>
        <DictionaryForm
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          languages={selectedLocationDetails?.data?.locales}
          defaultValues={currentWidget}
        />
      </Modal>

    </Layout>
  );
}
