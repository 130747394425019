import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { omit } from "lodash-es";
import { useDebounce } from "use-debounce";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import {
  getReservations,
  createReservation,
  editReservation,
} from "./services";
import removeNil from "lib/removeNil";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import YoutubeEmbed from "components/YoutubeEmbed";
import SearchForm from "./SearchForm";
import Form from "./Form";
import List from "components/List";
import { Button } from "components/Form";
import { FixedBottom } from "components/Layout/Styles";
import { GET_RESERVATIONS } from "config/queries";

export default function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [searchFormValues, setSearchFormValues] = useState({});
  const [searchFormValuesDebounced] = useDebounce(searchFormValues, 1000);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [newOverlayVisible, setNewOverlayVisible] = useState(false);
  const [currentReservation, setCurrentReservation] = useState(null);
  const { t } = useTranslation();
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const reservationList = useQuery(
    GET_RESERVATIONS +
      selectedLocation +
      JSON.stringify(searchFormValuesDebounced),
    () =>
      getReservations({
        placeId: selectedLocation,
        departure: Date.now(),
        ...searchFormValuesDebounced,
        accessToken: access.token,
      })
  );

  const onNewMutation = useMutation(
    (data) => createReservation(data, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(
          GET_RESERVATIONS +
            selectedLocation +
            JSON.stringify(searchFormValuesDebounced)
        );
        setResetForm(true);
        setNewOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onEditMutation = useMutation(
    (data) => editReservation(data, currentReservation._id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(
          GET_RESERVATIONS +
            selectedLocation +
            JSON.stringify(searchFormValuesDebounced)
        );
        setResetForm(true);
        setOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onNew = async (data) => {
    let dataCopy = { ...data };
    onNewMutation.mutate({
      ...removeNil(dataCopy),
      place: selectedLocation,
    });
    setTabIndex(0);
  };

  const onEdit = async (data) => {
    const cleanData = omit(data, ["place", "createdBy", "slug"]);

    let dataCopy = { ...cleanData };
    onEditMutation.mutate({
      ...removeNil(dataCopy),
    });
  };

  const onListClick = (item) => {
    if (!item) return;
    setOverlayVisible(true);
    setCurrentReservation({ ...item });
  };

  function prepareList() {
    if (Array.isArray(reservationList?.data?.results)) {
      const data = reservationList.data.results;
      return data
        .map((d) => {
          let tags = [];
          if (d?.arrival) {
            tags.push(d.arrival.split("T")[0]);
          }
          if (d?.departure) {
            tags.push(d.departure.split("T")[0]);
          }
          if (d?.status && d?.status?.length) {
            tags.push(d.status[d.status.length - 1]?.status);
          }

          return {
            ...d,
            id: d._id,
            name: d.room,
            reduceOpacity: moment(moment(d.departure).format()).isSameOrBefore(
              new Date()
            ),
            email: d?.customer?.email,
            tags,
            arrival: d?.arrival?.split("T")[0],
            departure: d?.departure?.split("T")[0],
          };
        })
        .sort((a, b) => a.name - b.name);
    }
  }

  let tabs = [];

  tabs.push({
    title: "🔍 " + t("reservations"),
    component: (
      <>
        <SearchForm
          defaultValues={searchFormValues}
          onSubmit={setSearchFormValues}
        />
        <List items={prepareList()} onClick={onListClick} />
      </>
    ),
  });

  tabs.push({
    title: t("help"),
    component: <YoutubeEmbed embedId="ScMzIvxBSi4" />,
  });

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}
        <FixedBottom>
          <Button onClick={() => setNewOverlayVisible(true)}>
            {t("form.add")} {t("reservation")}
          </Button>
        </FixedBottom>
      </Tabs>
      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <Form
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          defaultValues={currentReservation}
          reservationList={reservationList?.data?.results}
        />
      </Modal>
      <Modal visible={newOverlayVisible} setVisible={setNewOverlayVisible}>
        <Form
          onSubmit={onNew}
          buttonLabel={t("form.save")}
          resetForm={resetForm}
          reservationList={reservationList?.data?.results}
        />
      </Modal>
    </Layout>
  );
}
