import i18next, { locales } from "../../i18n";
import { Select } from "components/Form";

export default function LangSwitcher() {
    function onChange(e) {
        i18next.changeLanguage(e.target.value)
  }
  return (
    <Select onChange={onChange} defaultValue={i18next.language} small>
      {locales.map((l) => (
        <option key={l.name} value={l.symbol}>{l.name}</option>
      ))}
    </Select>
  );
}
