import { Player } from "@lottiefiles/react-lottie-player";

export default function LottieLogin({onClick}) {
  return (
    <div onClick={onClick} style={{cursor:'pointer'}}>
    <Player
      autoplay
      loop
      src="https://assets8.lottiefiles.com/packages/lf20_uecuu64m.json"
      style={{ height: "200px", width: "50vh" }}
      />
      </div>
  );
}
