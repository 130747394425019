import PropTypes from "prop-types";
import NavigationComponent from "components/Navigation";
import LangSwitcher from "components/LangSwitcher";
import {
  RightSideBar,
  MenuSideBar,
  Content,
  Container,
  Wrapper,

} from "./Styles";
import ListLocations from "../../routes/locations/List";
import OrderList from './OrderList'
import NavigationItems from "generated/menuItems.json";
import UpOuiLogo from "components/Logo";

const Layout = ({
  hide = false,
  children,
  hideLeft = false,
  hideRight = false,
  RightContent,
}) => {
  return (
    <>
      <Wrapper>
        {
          !hide && (
            <Container>
              {!hideLeft ? (
                <MenuSideBar>
                  <UpOuiLogo />
                  <NavigationComponent items={NavigationItems} />
                  <LangSwitcher />
                </MenuSideBar>
              ) : null}

              <Content fullscreen={hideLeft || hideRight }>
                <ListLocations />
                {children}
              </Content>
              {!hideRight ? (
                <RightSideBar>{RightContent || <OrderList />}</RightSideBar>
              ) : null}
            </Container>
          )
        }
      </Wrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  hide: PropTypes.bool,
  hideLeft: PropTypes.bool,
  hideRight: PropTypes.bool,
  RightContent: PropTypes.element,
  LeftContent: PropTypes.element,
};

export default Layout;
