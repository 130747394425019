import { toast } from "react-toastify";
import QRCode from "react-qr-code";
import useCopyToClipboard from "hooks/useCopyToClipboard";
import { useTranslation } from "react-i18next";
import Layout from "components/Layout";
import TitleWithSub from "components/TitleWithSub";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import YoutubeEmbed from "components/YoutubeEmbed";
import { Spacing } from "components/Layout/Spacing";
import { Text, SyntaxContainer } from "components/Typography"
import { CONCIERGE_BASE_URL, MICE_BASE_URL, FIDELITY_BASE_URL, KIOSK_BASE_URL } from "config/app";
import { API_BASE_URL } from "config/api"
import useLocation from "hooks/useLocation";


export default function App() {
  const [, copy] = useCopyToClipboard();
  const { t } = useTranslation();
  const selectedLocation = useLocation((state) => state.selected);
  const conciergeUrl = CONCIERGE_BASE_URL+'/h/'+selectedLocation
  const reputationUrl = CONCIERGE_BASE_URL + '/review/' + selectedLocation
  const kioskUrl = KIOSK_BASE_URL
  const minceUrl = MICE_BASE_URL + '/' + selectedLocation
  const fidelityUrl = FIDELITY_BASE_URL + '/h/' + selectedLocation
  const apiUrl = API_BASE_URL + 'reservation'
  const upouiNotifyUrl = 'https://play.google.com/store/apps/details?id=com.upoui.notify'
  const upouiCompass = 'https://compass.upoui.com'
  
  function handleClick(text) {
    copy(text)
    toast.success(t("copied"));
  }

  return (
    <Layout >
      <Tabs style={{ padding: "20px" }}>
        <TabList>
          <Tab>{t("integrations")}</Tab>
          <Tab>{t("help")}</Tab>
        </TabList>

        <TabPanel>         
            <Spacing top="md" />
            <TitleWithSub
              title={t('title.conciergeUrl')}
              sub={t('subtitle.conciergeUrl')}
            />
             <Spacing top="md" />
            <QRCode value={conciergeUrl} />
      
            <SyntaxContainer onClick={()=>handleClick(conciergeUrl)}>
              <Text>{conciergeUrl}</Text>
          </SyntaxContainer>
          
          <Spacing top="xl" />
            <TitleWithSub
              title={t('title.kiosk')}
              sub={t('subtitle.kiosk')}
            />
             <Spacing top="md" />
            <QRCode value={kioskUrl} />
      
            <SyntaxContainer onClick={()=>handleClick(kioskUrl)}>
              <Text>{kioskUrl}</Text>
          </SyntaxContainer>

          
            <Spacing top="xl" />
            <TitleWithSub
              title={t('title.reputationManager')}
              sub={t('subtitle.reputationManager')}
            />
             <Spacing top="md" />
            <QRCode value={reputationUrl} />
      
            <SyntaxContainer onClick={()=>handleClick(reputationUrl)}>
              <Text>{reputationUrl}</Text>
          </SyntaxContainer>
          
          <Spacing top="xl" />
          <TitleWithSub
              title={t('title.mice')}
              sub={t('subtitle.mice')}
            />
             <Spacing top="md" />
            <QRCode value={minceUrl} />
      
            <SyntaxContainer onClick={()=>handleClick(minceUrl)}>
              <Text>{minceUrl}</Text>
          </SyntaxContainer>

          <Spacing top="xl" />
          <TitleWithSub
              title={t('title.fidelity')}
              sub={t('subtitle.fidelity')}
            />
             <Spacing top="md" />
            <QRCode value={fidelityUrl} />
      
            <SyntaxContainer onClick={()=>handleClick(fidelityUrl)}>
              <Text>{fidelityUrl}</Text>
          </SyntaxContainer>

          <Spacing top="xl" />
            <TitleWithSub
              title={'UPoui Notify (Android)'}
              sub={'Orders alerts and notifications'}
            />
             <Spacing top="md" />
            <QRCode value={upouiNotifyUrl} />
      
            <SyntaxContainer onClick={()=>handleClick(upouiNotifyUrl)}>
              <Text>{upouiNotifyUrl}</Text>
          </SyntaxContainer>

          <Spacing top="xl" />
          <TitleWithSub
              title={t('AutoCMS')}
              sub={t('One link for all your public content')}
            />
             <Spacing top="md" />
            <QRCode value={API_BASE_URL+'cms/'+selectedLocation} />
      
            <SyntaxContainer onClick={()=>handleClick(API_BASE_URL+'cms/'+selectedLocation)}>
              <Text>[GET] {API_BASE_URL+'cms/'+selectedLocation}</Text>
          </SyntaxContainer>

          <Spacing top="xl" />
          <TitleWithSub
              title={t('Compass Nav')}
              sub={t('Navigate From Department A to B with ease')}
            />
             <Spacing top="md" />
            <QRCode value={upouiCompass+'/'+selectedLocation} />
      
            <SyntaxContainer onClick={()=>handleClick(upouiCompass+'/'+selectedLocation)}>
              <Text>{upouiCompass+'/'+selectedLocation}</Text>
          </SyntaxContainer>
          
          <Spacing top="xl" />
            <TitleWithSub
              title={t('title.pmsIntegration')}
              sub={t('subtitle.pmsIntegration')}
            />
             <Spacing top="md" />
          
      
            <SyntaxContainer onClick={()=>handleClick(API_BASE_URL)}>
            <Text>{apiUrl}</Text>
            <strong>Request Body</strong><br/>
            <hr />
            <strong>place</strong>: {selectedLocation}<br/>
            <strong>email</strong>: customer@----.com <br/>
            <strong>room</strong>: room number<br/>
            <strong>arrival</strong>: arrival date<br/>
            <strong>departure</strong>: departure date<br/>
         
            <Spacing top="md" />
            </SyntaxContainer>
            <Spacing top="xl" />
         
        </TabPanel>
        <TabPanel>
          <YoutubeEmbed embedId="ScMzIvxBSi4" />
        </TabPanel>
      </Tabs>

     
    </Layout>
  );
}
