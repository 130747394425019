import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, useFieldArray } from "react-hook-form";
import Toggle from "react-toggle";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import Modal from "components/Layout/Modal";
import LottieMedia from "routes/dashboard/LottieMedia";
import WidgetUrlBuilder from "./WidgetUrlBuilder";
import { Input, Select, Button, Error } from "components/Form";

const sizesList = [
  {
    name: "1/3",
    value: 33,
  },
  {
    name: "1/2",
    value: 50,
  },
  {
    name: "2/3",
    value: 66,
  },
  {
    name: "Full",
    value: 100,
  },
];

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const [widgetOverlayVisible, setWidgetOverlayVisible] = useState(false);
  const [showHeader, setShowHeader] = useState(false);
  const [media, setMedia] = useState([]);
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  const formData = watch("widgets");
  const [selectedInputIndex, setSelectedInputIndex] = useState(0);
  //console.log(formData);

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "widgets",
  });

  const onSubmitHelper = (data) => {
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;
    delete data.tags;
    onSubmit({ ...data, media: mediaIds, showHeader });
  };

  useEffect(() => {
    if (!!defaultValues?.media) {
      setMedia(defaultValues.media);
    }
    if (!!defaultValues?.showHeader) {
      setShowHeader(defaultValues.showHeader)
    }
  }, [defaultValues]);

  function handleWidgetUrlGenerator(url) {
    setValue(`widgets.${selectedInputIndex}.url`, url);
  }

  return (
    <>
      <Spacing bottom={"lg"} />

      {defaultValues?.shortId && (
        <div
          style={{
            border: "5px dashed rgba(0,0,0,.5)",
            width: "fit-content",
            height: "fit-content",
            padding: "20px",
            textTransform: "none !important",
          }}
        >
          <h1 style={{ margin: 0, textTransform: "none !important" }}>
            {defaultValues.shortId}
          </h1>
        </div>
      )}

      {defaultValues?.shortId && <Spacing bottom={"lg"} />}

      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.kiosk.name")}
          />
          {errors.name && <Error>Kiosk Name is required</Error>}
        </Row>

        <Spacing bottom={"sm"} />

        <Row>
          <Toggle
            checked={showHeader}
            id="show-header"
            onChange={() => setShowHeader(!showHeader)}
          />
          {t("Show Header")}
        </Row>

        <Spacing bottom={"sm"} />
        {fields.map((item, index) => (
          <div
            style={{
              background: "white",
              marginBottom: "20px",
              padding: "15px",
              border: formData[index]?.url
                ? "10px solid green"
                : "10px solid black",
            }}
          >
            <Input
              hidden={!formData[index]?.url}
              placeholder="Url"
              {...register(`widgets.${index}.url`)}
            />

            <LottieMedia
              onClick={() => {
                setSelectedInputIndex(index);
                setWidgetOverlayVisible(true);
              }}
            />

            <Select {...register(`widgets.${index}.width`)} small>
              {sizesList.map((l) => (
                <option key={l.name + " width"} value={l.value}>
                  {l.name} width
                </option>
              ))}
            </Select>
            <Spacing bottom={"xs"} />
            <Select {...register(`widgets.${index}.height`)} small>
              {sizesList.map((l) => (
                <option key={l.name + " height"} value={l.value}>
                  {l.name} height
                </option>
              ))}
            </Select>

            <Spacing bottom={"sm"} />
            <Button type="button" onClick={() => remove(index)} secondary>
              {t("form.delete")}
            </Button>
            <Spacing bottom={"lg"} />
          </div>
        ))}

        <Button
          secondary
          width="200px"
          type="button"
          onClick={() => {
            append({
              url: null,
              width: 100,
              height: 100,
            });
          }}
        >
          {t("form.add")}
        </Button>

        <Spacing bottom={"lg"} />

        <Button type="submit">{buttonLabel}</Button>
      </form>

      <Modal
        visible={widgetOverlayVisible}
        setVisible={setWidgetOverlayVisible}
      >
        <WidgetUrlBuilder
          selectedIndex={selectedInputIndex}
          onChange={handleWidgetUrlGenerator}
          closeModal={() => setWidgetOverlayVisible(false)}
        />
      </Modal>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
