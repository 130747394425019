import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Row, Column } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Error } from "components/Form";
import removeNil from "lib/removeNil";

export default function SearchForm({
    onSubmit,
    defaultValues,
    resetForm,
}) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    onSubmit(removeNil({...data}));
  };

  return (
    <>
      <form onChange={handleSubmit(onSubmitHelper)}>
        <Row>
          <Column>
            <Input
              {...register("email")}
              placeholder={t("form.email")}
              type="email"
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email && <Error>Email Address is required</Error>}
          </Column>
          <Column>
            <Input {...register("room")} placeholder={t("form.room.number")} />
          </Column>
        </Row>
       
        <Row>
          <Column>
          <Input
              {...register("passeport")}
              placeholder={t("form.passeport")}
            />
          </Column>
          <Column>
            <Input
              {...register("flightNumber")}
              placeholder={t("form.flightNumber")}
            />
          </Column>
        </Row>
      </form>
    </>
  );
}

SearchForm.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
