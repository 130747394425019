import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Title } from "components/Typography";
import { Column, Row } from "components/Layout/Styles";
import { Ul, Li } from "./Style";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Error,
  Select,
  MultiSelect,
  TextArea,
} from "components/Form";
import days from "config/days";
import getTimes from "config/times";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  members,
}) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "openingHours", // unique name for your Field Array
  });

  const timeList = useMemo(() => getTimes(), []);

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const staff = data?.staff ? data.staff.map((s) => s.value) : [];
    onSubmit({ ...data, staff });
  };

  const options =
    members && members[0]
      ? members.map((member) => {
          return { value: member._id, label: member.email };
        })
      : null;

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.department.name")}
          />
          {errors.email && <Error>Department Name is required</Error>}

          <Column></Column>
        </Row>
        <Row>
          <Column>
            <Input {...register("phone")} placeholder={t("form.phone")} />
          </Column>

          <Column>
            {" "}
            <Input
              {...register("email")}
              placeholder={t("form.email")}
              type="email"
            />
          </Column>
        </Row>
        <Row>
          <TextArea
            {...register("description")}
            aria-invalid={errors.description ? "true" : "false"}
            placeholder={t("form.category.description")}
          />
        </Row>
      


        {options && (
          <Controller
            name="staff"
            control={control}
            render={({ field }) => (
              <MultiSelect
                control={control}
                isMulti
                options={options}
                placeholder={t("members")}
                {...field}
              />
            )}
          />
        )}
          <Row>
          <Column>
            <Input {...register("latitude")} placeholder={t("Latitude")} />
          </Column>

          <Column>
            {" "}
            <Input
              {...register("longitude")}
              placeholder={t("Longitude")}
            />
          </Column>
        </Row>

        <Spacing bottom={"lg"} />
        <Title>{t("opening.hours")}</Title>
        <Button
          secondary
          width='200px'
          type="button"
          onClick={() => {
            append({
              day: 0,
              startTime: 0,
              endTime: 0,
              maxClientPerHour: 0,
            });
          }}
        >
          {t('form.add')}
        </Button>
        <Spacing bottom={"lg"} />
        {fields.map((item, index) => (
          <Ul>
            <Li key={item.id}>
              <label>
                {t('form.select.day')}
                <Select {...register(`openingHours.${index}.day`)} small>
                  {days.map((l) => (
                    <option key={l.name} value={l.value}>
                      {t(l.name)}
                    </option>
                  ))}
                </Select>
              </label>
              <label>
              {t('form.start.time')}
              <Select {...register(`openingHours.${index}.startTime`)} small>
                {timeList.map((l) => (
                  <option key={l.name} value={l.value}>
                    {l.name}
                  </option>
                ))}
                </Select>
              </label>
              <label>
              {t('form.end.time')}
              <Select {...register(`openingHours.${index}.endTime`)} small>
                {timeList.map((l) => (
                  <option key={l.name} value={l.value}>
                    {l.name}
                  </option>
                ))}
                </Select>
                </label>
              <label>
              {t('form.max.customer')}
              <Input
                {...register(`openingHours.${index}.maxClientPerHour`)}
                type="number"
                placeholder="Max Customer Per hour"
                />
                </label>
            </Li>
            <Button type="button" onClick={() => remove(index)} secondary>
              {t('form.delete')}
            </Button>
            <Spacing bottom={"lg"} />
          </Ul>
        ))}

        <Spacing bottom={"lg"} />
        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
