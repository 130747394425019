import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Select } from "components/Form";

export default function Filter({
  categoryList,
  onChange
}) {
  const { t } = useTranslation();

  return (
    <>
      {categoryList && categoryList[0] ? (
        <Select placeholder={t("form.category")} small onChange={ onChange }>
          <option value={''}>{t("all")}</option>
          {categoryList.map((c, k) => (
            <option value={c._id} key={c + k}>
              {c.name}
            </option>
          ))}
        </Select>
      ) : null}
    </>
  );
}

Filter.prototype = {
  categoryList: PropTypes.array,
  onChange: PropTypes.func
};
