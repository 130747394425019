import {  fetchit } from 'lib'

import {
    API_PRODUCT
} from 'config/api'


export function createProduct(data, accessToken) {
    return fetchit(API_PRODUCT, data, 'POST', accessToken)
}

export function editProduct(data, productId, accessToken) {
    return fetchit(API_PRODUCT+'/'+productId, data, 'PATCH', accessToken)
}

export function getProducts({ placeId, accessToken, limit=8, page=0, categories, department }) {
    if (!placeId) return
    let url = `${API_PRODUCT}/?sortBy=updatedAt:desc&limit=${limit}&page=${page}&place=${placeId}`
    if (categories) {
        url += `&categories=${categories}`
    }
    if (department) {
        url += `&department=${department}`
    }
    return fetchit(url, null, 'GET', accessToken)
}


export function deleteProduct(productId, accessToken) {
    if(!productId) return
    return fetchit(API_PRODUCT+'/'+productId, null, 'DELETE', accessToken)
}
