import PropTypes from "prop-types";
import {
  Ul,
  Li,
  RightIcon,
  Item,
  TagContainer,
  TagItems,
  DictionaryContainer,
} from "./List.style";
import Pagination from "components/Pagination";
import DeleteIcon from "components/Icons/DeleteIcon";
import DictionaryIcon from "components/Icons/DictionaryIcon";
import CalendarIcon from "components/Icons/CalendarIcon";
import stringToColor, { getContrast } from "lib/stringToColor";
import { Spacing } from "components/Layout/Spacing";

export default function List({
  items,
  onDeleteBtn,
  showCalendar,
  onClick = () => {},
  onDictionaryClick,
  onChangePage,
  currentPage,
  totalPages,
}) {
  return (
    <Ul>
      {Array.isArray(items) &&
        items.map((item, k) => {
          const { name, _id, tags, email, reduceOpacity } = item;
          return (
            <Li key={_id + k} reduceOpacity={reduceOpacity}>
              <Item onClick={() => onClick(item)}>{name || email}</Item>
              {Array.isArray(tags) && (
                <TagContainer>
                  {tags.map((t, e) => (
                    <TagItems
                      key={_id + e + t + k}
                      backgroundColor={stringToColor(t)}
                      color={getContrast(stringToColor(t))}
                    >
                      {t}
                    </TagItems>
                  ))}
                </TagContainer>
              )}

              {typeof tags === "string" && (
                <TagContainer>
                  <TagItems
                    backgroundColor={stringToColor(tags)}
                    color={getContrast(stringToColor(tags))}
                  >
                    {tags}
                  </TagItems>
                </TagContainer>
              )}

              {typeof onDictionaryClick === "function" && (
                <DictionaryContainer onClick={() => onDictionaryClick(item)}>
                  <DictionaryIcon />
                </DictionaryContainer>
              )}

              {typeof onDeleteBtn === "function" && (
                <RightIcon onClick={() => onDeleteBtn(_id)}>
                  <DeleteIcon />
                </RightIcon>
              )}

              {showCalendar && (
                <DictionaryContainer
                  onClick={() =>
                    window.open(`/staff/${_id}`, "_blank").focus()
                  }
                >
                  <CalendarIcon />
                </DictionaryContainer>
              )}
            </Li>
          );
        })}
      {onChangePage && (
        <>
          <Spacing top={"xl"} />
          <Pagination
            onChange={onChangePage}
            current={currentPage}
            total={totalPages}
          />
        </>
      )}
      <Spacing bottom={"xl"} />
    </Ul>
  );
}

List.propTypes = {
  onDeleteBtn: PropTypes.func,
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      tags: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      email: PropTypes.string,
    })
  ),
};
