import { Player } from "@lottiefiles/react-lottie-player";

export default function LottiePendingOrder() {
  return (
    <Player
      autoplay
      loop
      src="https://assets2.lottiefiles.com/packages/lf20_5ohCYt.json"
      style={{ height: "500px", width: "500px" }}
    />
  );
}
