import {  fetchit } from 'lib'

import {
    API_RESERVATION
} from 'config/api'


export function createReservation(data, accessToken) {
    return fetchit(API_RESERVATION, data, 'POST', accessToken)
}

export function editReservation(data, productId, accessToken) {
    return fetchit(API_RESERVATION+'/'+productId, data, 'PATCH', accessToken)
}

export function getReservations({ placeId, accessToken, departure, email, room, passeport, flightNumber  }) {
    if (!placeId) return
    let uri = `${API_RESERVATION}/?place=${placeId}&sortBy=departure:desc`
    if (!email && !passeport && departure) {
        uri += `&departure=${departure}`
    }
    if (email) {
        uri += `&email=${email}`
    }
    if (room) {
        uri += `&room=${room}`
    }
    if (passeport) {
        uri += `&passeport=${passeport}`
    }
    if (flightNumber) {
        uri += `&flightNumber=${flightNumber}`
    }

    return fetchit(uri, null, 'GET', accessToken)
}