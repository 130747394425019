import { fetchit } from "lib";

import { API_SCHEDULE } from "config/api";

export function createSchedule(data, accessToken) {
  return fetchit(API_SCHEDULE, data, "POST", accessToken);
}

export function editSchedule(data, schedulelId, accessToken) {
  return fetchit(API_SCHEDULE + "/" + schedulelId, data, "PATCH", accessToken);
}

export function getSchedules({ departmentId, accessToken, year, month, day }) {
  if (!departmentId || !year || !month || !day) return;
  return fetchit(
    `${API_SCHEDULE}/?department=${departmentId}&year=${year}&month=${month}&day=${day}`,
    null,
    "GET",
    accessToken
  );
}

export function deleteSchedules(schedulelId, accessToken) {
  return fetchit(API_SCHEDULE + "/" + schedulelId, null, "DELETE", accessToken);
}
