import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  background-color: ${(p) =>
    p.dark ? "rgba(0,0,0,.06)" : p.theme.colors.white};
  margin-bottom: ${(p) => (p.marginBottom ? "10px" : 0)};
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden ;
`;