import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Input, Button } from "components/Form";
import { WIDGET_BASE_URL } from "config/api";
import useLocation from "hooks/useLocation";
import useUser from "hooks/useUser";
import { getHotelById } from "../../locations/services";

const Weather = ({ onChange }) => {
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const selectedLocation = useLocation((state) => state.selected);
  const { access } = useUser((state) => state.user);

  const selectedLocationDetails = useQuery(selectedLocation, () =>
  getHotelById({
    hotelId: selectedLocation,
    accessToken: access.token,
  })
  );
  
  useEffect(() => {
    if (selectedLocationDetails?.data?.loc && !latitude) {
      setLatitude(selectedLocationDetails?.data?.loc[1])
      setLongitude(selectedLocationDetails?.data?.loc[0])
    }
  },[selectedLocationDetails, latitude])


  return (
    <>
      <Input
        placeholder="Latitude"
        defaultValue={latitude}
        name="latitude"
        onChange={(e) => setLatitude(e.target.value)}
      />
      <Input
        placeholder="Longitude"
        name="longitude"
        defaultValue={longitude}
        onChange={(e) => setLongitude(e.target.value)}
      />

      <Button onClick={() => onChange(`${WIDGET_BASE_URL}/weather?latitude=${latitude}&longitude=${longitude}`)}>OK</Button>
    </>
  );
};

export default Weather;
