import styled from "styled-components";

export const Container = styled.div`
  padding: 10px;
  background-color: ${(p) =>
    p.dark ? "rgba(0,0,0,.06)" : p.theme.colors.white};
  margin-bottom: ${(p) => (p.marginBottom ? "10px" : 0)};
  width: 100%;
  height: 100vh;
  position: relative;
`;

export const EventContainer = styled.div`
  padding: 10px;
  color: white;
`;

export const ButtonContainer = styled.div`
 align-items: center;
 position: relative ;
  box-sizing: border-box;
  display: inline-flex;
  padding: calc(0.275rem - 1px) calc(0.275rem - 1px);
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  margin:0 !important;
`

export const Badge = styled.div`
  position: absolute;
  display:table;
  text-align: center ;
  align-items: center ;
  top:0;
  z-index:999;
  background-color: #e91e63;
  color: white;
  width: 20px;

`

export const TimeButton = styled.button`
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
  :hover,
  :focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: rgba(0, 0, 0, 0.65);
  }

  :hover {
    transform: translateY(-1px);
  }

  :active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
  }
`;

export const SpotContainer = styled.div`
  padding: 10px 0 0px 0;
  margin-bottom: 60px;
  border-left: 10px solid rgba(255,255,255,1);
  padding-left: 10px;
  h2{
    margin: 0;
  }
`

export const SpotItem = styled.div`
  border-bottom:1px solid rgba(255,255,255,.1);
  cursor: pointer;
`

export const Ul = styled.ul`
    list-style: none;
`
export const Li = styled.li`
    label{
        font-size: 11px;
    }
`