import React from "react";
import { useTranslation } from "react-i18next";
import { ActionButton, Container, InfosContainer, Subtitle, Title, UserInfo } from "./UserCard.styles";


const UserCard = ({
  name,
  email,
  points,
  onClick,
  dark,
  marginBottom,
}) => {
  const { t } = useTranslation();
  return (
    <Container dark={dark} marginBottom={marginBottom}>
        <InfosContainer>
            <UserInfo>
            <Title>{name}</Title>
            {email && <Subtitle>{email}</Subtitle>}
            </UserInfo>
            {points && <Title>{t("form.totalPoints")}{points}</Title>}
        </InfosContainer>
        <ActionButton onClick={onClick} success>{t("form.redeem")}</ActionButton>
    </Container>
  );
};

export default UserCard;
