import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import omit from "lodash-es/omit";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import { getHotelsByStaffId, createHotel, editHotel } from "./services";
import removeNil from "lib/removeNil";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import LocationForm from "./LocationForm";
import MembersList from "./MembersList";
import YoutubeEmbed from "components/YoutubeEmbed";
import { GET_HOTELS } from "config/queries";
import { FixedBottom } from "components/Layout/Styles";
import { Button } from "components/Form";
import Modal from "components/Layout/Modal";

export default function App() {
  const [newOverlayVisible, setNewOverlayVisible] = useState(false);
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { user, access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);
  const setSelectedLocation = useLocation(state=>state.setSelected)

  const hotelList = useQuery(GET_HOTELS, () =>
    getHotelsByStaffId({
      userId: user._id,
      accessToken: access.token,
    })
  );

  const onNewMutation = useMutation((data) => createHotel(data, access.token), {
    onSuccess: (data) => {
      toast.success(t("update.success"));
      queryClient.invalidateQueries(GET_HOTELS);
      setResetForm(true);
      setTabIndex(0);
      setNewOverlayVisible(false);
      if (data._id) {
        setSelectedLocation(data._id)
      }
      
    },
    onError: (err) => {
      console.error(err);
      toast.error("An error occured");
    },
  });

  const onEditMutation = useMutation(
    (data) => editHotel(data, selectedLocation, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_HOTELS);
      },
      onError: (err) => {
        console.error(err);
        toast.error(t("update.error"));
      },
    }
  );

  const onNew = async (data) => {
    let dataCopy = { ...data };
    onNewMutation.mutate({
      ...removeNil(dataCopy),
      staff: [user._id],
      createdBy: user._id,
    });
  };

  const onEdit = (data) => {
    const cleanData = omit(data, [
      "_id",
      "createdBy",
      "slug",
      "createdAt",
      "updatedAt",
      "__v",
    ]);

    onEditMutation.mutate({
      ...removeNil(cleanData),
    });
  };

  let tabs = [];
  if (hotelList.data && hotelList.data[0] && selectedLocation) {
    tabs.push({
      title:
        "✏️ " +
        hotelList.data.filter((d) => d._id === selectedLocation)[0]?.name,
      component: (
        <LocationForm
          selectedLocation={selectedLocation}
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          defaultValues={
            hotelList.data.filter((d) => d._id === selectedLocation)[0]
          }
        />
      ),
    });
  }



  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
          {selectedLocation && <Tab>{t("staff")}</Tab>}
          <Tab>{t("help")}</Tab>
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}

        {selectedLocation && (
          <TabPanel>
            <MembersList />
          </TabPanel>
        )}
        <TabPanel>
          <YoutubeEmbed embedId="ScMzIvxBSi4" />
        </TabPanel>
        {
          !selectedLocation &&   <FixedBottom>
          <Button onClick={() => setNewOverlayVisible(true)}>
            {t("form.add")} {t("location")}
          </Button>
        </FixedBottom>
        }
      
      </Tabs>
      <Modal visible={newOverlayVisible} setVisible={setNewOverlayVisible}>
        <LocationForm
          onSubmit={onNew}
          buttonLabel={t("form.save")}
          resetForm={resetForm}
        />
      </Modal>
    </Layout>
  );
}
