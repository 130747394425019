import {  fetchit } from 'lib'

import {
    API_UPLOAD
} from 'config/api'

export function getMediasByHotelId({placeId, accessToken}) {
    return fetchit(API_UPLOAD+'/'+placeId+'?sortBy=createdAt:desc&limit=100', null, 'GET', accessToken)
}

