import {  fetchit } from 'lib'

import {
    API_KIOSKS
} from 'config/api'


export function createKiosk(data, accessToken) {
    return fetchit(API_KIOSKS, data, 'POST', accessToken)
}

export function editKiosk(data, kioskId, accessToken) {
    return fetchit(API_KIOSKS+'/'+kioskId, data, 'PATCH', accessToken)
}

export function getKiosks({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_KIOSKS+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteKiosk(kioskId, accessToken) {
    if(!kioskId) return
    return fetchit(API_KIOSKS+'/'+kioskId, null, 'DELETE', accessToken)
}
