import { useState, useMemo } from "react";
import { Input, Button } from "components/Form";
import { WIDGET_BASE_URL } from "config/api";

const CountDown = ({ onChange }) => {
    const [formValues, setFormValues] = useState();
  
    const handleInputChange = (value) => {
      setFormValues(new Date(value).getTime());
    };
  
    const compiledUrl = useMemo(() => {
      return `${WIDGET_BASE_URL}/countdown?time=${formValues}`;
    }, [formValues]);
  
    return (
      <>
        <Input
          type='datetime-local'
          placeholder="When"
          name="video"
          onChange={(e) => handleInputChange(e.target.value, 0)}
        />
        <Button onClick={() => onChange(compiledUrl)}>OK</Button>
      </>
    );
  };
  
  export default CountDown