import { Player } from "@lottiefiles/react-lottie-player";

export default function LottieNoOrders() {
  return (
    <Player
      autoplay
      loop
      src="https://assets5.lottiefiles.com/packages/lf20_dflqwg4a.json"
      style={{ height: "500px", width: "50vh" }}
    />
  );
}
