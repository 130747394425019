import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { Title } from "components/Typography";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Button,  Select } from "components/Form";
import orderStatuses from "config/orderStatus";


export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm
}) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    if (!data?.status) {
      console.error('status missing')
      return
    }
    onSubmit({ status: data.status });
  };

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Title>Room: {defaultValues.room}</Title>
        <Row>
          {defaultValues?.orderItem &&
            defaultValues.orderItem.map((product, key) => {
              if (product.product) {
                return (
                  <Row
                    key={product.product._id + key}
                    style={{ display: "block" }}
                  >
                    <h3>
                      {" "}
                      {product.qty} x {product.product.name}{" "}
                    </h3>
                    <span>
                      {" "}
                      {product.product.price} {product.product.currency}
                    </span>
                    <p>
                      {" "}
                      <strong>Note: </strong> {product.note}
                    </p>

                    <hr />
                  </Row>
                );
              }

              return null;
            })}
        </Row>
        <Spacing bottom={"xl"} />
        <Spacing bottom={"xl"} />
        <Spacing bottom={"xl"} />
        <Row>
          <Title>{t("update.status")}</Title>
          {orderStatuses && orderStatuses[0] && (
            <Select
              {...register("status")}
              placeholder={t("form.reservation.status")}
              small
            >
              <option disabled>{t("form.reservation.status")}</option>

              {orderStatuses.map((c, k) => (
                <option value={c} key={c + k}>
                  {t(c)}
                </option>
              ))}
            </Select>
          )}
        </Row>

        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
  departmentList: PropTypes.array,
};
