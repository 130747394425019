import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { useTranslation } from "react-i18next";
import { Input, Button, Select } from "components/Form";
import getTimes from "config/times";
import { FixedBottom } from "components/Layout/Styles";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  products,
  onDelete,
}) {
  const { t } = useTranslation();
  const { register, reset, handleSubmit } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  const timeList = useMemo(
    () => getTimes(defaultValues?.hour),
    [defaultValues]
  );

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    let dataCopy = { ...data };
    delete dataCopy.hour;
    delete dataCopy.department;
    delete dataCopy.bucket;
    delete dataCopy.createdBy;
    onSubmit({ ...dataCopy });
  };

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Select {...register("product")} small>
          {products.map((l) => (
            <option key={l._id} value={l._id}>
              {t(l.name)}
            </option>
          ))}
        </Select>

        <Select {...register("startTime")} small>
          {timeList.map((l) => (
            <option key={l.name} value={l.value}>
              {l.name}
            </option>
          ))}
        </Select>

        <Select {...register("endTime")} small>
          {timeList.map((l) => (
            <option key={l.name} value={l.value}>
              {l.name}
            </option>
          ))}
        </Select>

        <Input {...register("room")} type="number" placeholder="Room Number" />

        <Input {...register("comment")} type="text" placeholder="Comment" />

        <Spacing bottom={"lg"} />

        <Spacing bottom={"lg"} />
        <Button type="submit">{buttonLabel}</Button>
      </form>

      {defaultValues?.department && (
        <FixedBottom onClick={() => onDelete(defaultValues._id)} style={{cursor:"pointer"}}>
          ❌ {t("form.delete")}
        </FixedBottom>
      )}
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
