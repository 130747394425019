import {  fetchit } from 'lib'

import {
    API_CATEGORY
} from 'config/api'


export function createCategory(data, accessToken) {
    return fetchit(API_CATEGORY, data, 'POST', accessToken)
}

export function editCategory(data, categoryId, accessToken) {
    return fetchit(API_CATEGORY+'/'+categoryId, data, 'PATCH', accessToken)
}

export function getCategories({ placeId, accessToken, limit=8, page=0 }) {
    if (!placeId) return
    const url = `${API_CATEGORY}/?limit=${limit}&page=${page}&place=${placeId}`
    return fetchit(url, null, 'GET', accessToken)
}

export function deleteCategory(categoryId, accessToken) {
    if(!categoryId) return
    return fetchit(API_CATEGORY+'/'+categoryId, null, 'DELETE', accessToken)
}
