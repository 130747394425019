import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { authorize } from "./services";
import useUser from "hooks/useUser";
import useUuid from "hooks/useUuid";
import useTimeout from "hooks/useTimeout";
import useInterval from "hooks/useInterval";
import { REDIRECT_PATH } from "config/paths";
import { AUTH_TIMEOUT, AUTH_INTERVAL } from "config/auth";
import LottieCheckEmail from "./LottieCheckEmail";
import { Title, Text } from "components/Typography"
import { Row, Column } from "components/Layout/Styles";
import { Container, LoginBox } from "./Style";

export default function RefreshTokensComponent() {
  const user = useUser((state) => state.user);
  const setUser = useUser((state) => state.setUser);
  const uniqueId = useUuid((state) => state.uuid);
  const params = useParams();
  const { uuid, email } = params;
  let navigate = useNavigate();

  const mutation = useMutation(() => authorize(email, uuid), {
    onSuccess(data) {
      data && data.user && setUser(data)
    }
  })

  useInterval(() => {
    if (!!uuid && uuid === uniqueId) {
      mutation.mutate();
    }
  }, AUTH_INTERVAL);

  useTimeout(() => {
    navigate(REDIRECT_PATH);
  }, AUTH_TIMEOUT)

  useEffect(() => {
    if (user && user.user) {
      navigate(REDIRECT_PATH);
    }
  }, [user, navigate]);

  return (
    <Row>
      <Column>
        <Container>
      <LoginBox>
        
            <Title>Please check your emails (🕵️ even spam)</Title>
            <Text>You have 2 minutes before the session expires.</Text>
      </LoginBox>
        </Container>
      </Column>
      <Column style={{backgroundColor:'#182227'}}>
      <LottieCheckEmail />
      </Column>
      </Row>
  )

}
