export default function HomeLine({
    size = 24,
    color = "black"
  }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        width={size}
        height={size}
        fill={color}
      >
  <path d="M490.667,42.667H380.346C371.562,17.808,347.872,0,320,0s-51.562,17.808-60.346,42.667h-49.974
				C200.895,17.808,177.206,0,149.333,0S97.772,17.808,88.987,42.667H21.333C9.551,42.667,0,52.218,0,64v106.667v320
				C0,502.449,9.551,512,21.333,512h469.333c11.782,0,21.333-9.551,21.333-21.333v-320V64C512,52.218,502.449,42.667,490.667,42.667
				z M42.667,85.333h64C118.449,85.333,128,75.782,128,64c0-11.791,9.542-21.333,21.333-21.333S170.667,52.209,170.667,64
				s-9.542,21.333-21.333,21.333c-11.782,0-21.333,9.551-21.333,21.333c0,11.782,9.551,21.333,21.333,21.333
				c27.872,0,51.562-17.808,60.346-42.667h67.654c11.782,0,21.333-9.551,21.333-21.333c0-11.791,9.542-21.333,21.333-21.333
				S341.333,52.209,341.333,64S331.791,85.333,320,85.333c-11.782,0-21.333,9.551-21.333,21.333
				c0,11.782,9.551,21.333,21.333,21.333c27.872,0,51.562-17.808,60.346-42.667h88.987v64H42.667V85.333z M469.333,469.333H42.667
				V192h426.667V469.333z"/>
			<path d="M85.333,277.333H128c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333H85.333
				C73.551,234.667,64,244.218,64,256S73.551,277.333,85.333,277.333z"/>
			<path d="M192,277.333h106.667c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333H192
				c-11.782,0-21.333,9.551-21.333,21.333S180.218,277.333,192,277.333z"/>
			<path d="M362.667,277.333h64c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333h-64
				c-11.782,0-21.333,9.551-21.333,21.333S350.885,277.333,362.667,277.333z"/>
			<path d="M85.333,362.667h85.333c11.782,0,21.333-9.551,21.333-21.333c0-11.782-9.551-21.333-21.333-21.333H85.333
				C73.551,320,64,329.551,64,341.333C64,353.115,73.551,362.667,85.333,362.667z"/>
			<path d="M213.333,341.333c0,11.782,9.551,21.333,21.333,21.333h106.667c11.782,0,21.333-9.551,21.333-21.333
				c0-11.782-9.551-21.333-21.333-21.333H234.667C222.885,320,213.333,329.551,213.333,341.333z"/>
			<path d="M426.667,320h-21.333C393.551,320,384,329.551,384,341.333c0,11.782,9.551,21.333,21.333,21.333h21.333
				c11.782,0,21.333-9.551,21.333-21.333C448,329.551,438.449,320,426.667,320z"/>
			<path d="M85.333,448h85.333c11.782,0,21.333-9.551,21.333-21.333s-9.551-21.333-21.333-21.333H85.333
				c-11.782,0-21.333,9.551-21.333,21.333S73.551,448,85.333,448z"/>
			<path d="M341.333,405.333H234.667c-11.782,0-21.333,9.551-21.333,21.333S222.885,448,234.667,448h106.667
				c11.782,0,21.333-9.551,21.333-21.333S353.115,405.333,341.333,405.333z"/>
			<path d="M426.667,405.333h-21.333c-11.782,0-21.333,9.551-21.333,21.333S393.551,448,405.333,448h21.333
				c11.782,0,21.333-9.551,21.333-21.333S438.449,405.333,426.667,405.333z"/>
      </svg>
    )
}