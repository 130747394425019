import { useState, useMemo } from "react";
import { Input, Button } from "components/Form";
import { Spacing } from "components/Layout/Spacing";
import ModalGallery from "routes/media/ModalGallery";
import prependHttp from "lib/prependHttp";
import { WIDGET_BASE_URL } from "config/api";

const VideoPlayerWidget = ({ onChange }) => {
  const [inputFields, setInputFields] = useState(["1"]);
  const [medias, setMedias] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [urls, setUrls] = useState();

  const handleInputChange = (value, index) => {
    let newFormValues = [...formValues];
    newFormValues[index] = value && value.length ? prependHttp(value) : null;
    setFormValues(newFormValues.filter((a) => !!a));
  };

  const compiledUrl = useMemo(() => {
    const elems = [...formValues, ...medias].filter((a) => !!a).map(e=>prependHttp(e));
    setUrls(elems);
    return `${WIDGET_BASE_URL}/player?url=${elems.join(",")}`;
  }, [formValues, medias]);

  return (
    <>
      {inputFields.map((_, index) => {
        return (
          <>
            <Input
              placeholder="Youtube, Vimeo, facebook, wistia, dailymotion... "
              name="video"
              onChange={(e) => handleInputChange(e.target.value, index)}
            />
            {
              index * 1 > 0 &&  <Button
              disabled={index * 1 === 0}
              secondary
              onClick={() => {
                let data = [...inputFields];
                data.splice(index, 1);
                setInputFields(data);
                handleInputChange(null, index)
              }}
            >
              Remove
            </Button>
            }
           
          </>
        );
      })}
      <Spacing bottom={"xs"} />

      <Button onClick={() => setInputFields([...inputFields, 0])} secondary>
        Add 
      </Button>

      <Spacing bottom={"lg"} />
      <ModalGallery
        onClick={(medias) => setMedias(medias.map((m) => m.location))}
        isVideo
        isMany
      />
      <Spacing bottom={"lg"} />
      <Button onClick={() => onChange(compiledUrl)}>OK</Button>
      <Spacing bottom={"lg"} />
      <ul>{urls && urls.map((u) => <li key={u}>{u}</li>)}</ul>
    </>
  );
};

export default VideoPlayerWidget;
