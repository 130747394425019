import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import omit from "lodash-es/omit";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import { getHotelsByStaffId, editHotel } from "../locations/services";
import removeNil from "lib/removeNil";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import SettingForm from "./SettingForm";
import YoutubeEmbed from "components/YoutubeEmbed";
import { GET_HOTELS } from "config/queries";

export default function App() {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const queryClient = useQueryClient();
  const { user, access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const hotelList = useQuery(GET_HOTELS, () =>
    getHotelsByStaffId({
      userId: user._id,
      accessToken: access.token,
    })
  );

  const onEditMutation = useMutation(
    (data) => editHotel(data, selectedLocation, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_HOTELS);
      },
      onError: (err) => {
        console.error(err);
        toast.error(t("update.error"));
      },
    }
  );

  const onEdit = (data) => {
    const cleanData = omit(data, [
      "_id",
      "createdBy",
      "slug",
      "createdAt",
      "updatedAt",
      "__v",
      "locales"
    ]);

    onEditMutation.mutate({
      ...removeNil(cleanData),
    });
  };

  let tabs = [];
  if (hotelList.data && hotelList.data[0] && selectedLocation) {
    tabs.push({
      title:
        "✏️ " +
        hotelList.data.filter((d) => d._id === selectedLocation)[0]?.name,
      component: (
        <SettingForm
          selectedLocation={selectedLocation}
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          defaultValues={
            hotelList.data.filter((d) => d._id === selectedLocation)[0]
          }
        />
      ),
    });
  }



  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
          
          <Tab>{t("help")}</Tab>
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}
        <TabPanel>
          <YoutubeEmbed embedId="ScMzIvxBSi4" />
        </TabPanel>
      </Tabs>
    </Layout>
  );
}
