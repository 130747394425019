import {  fetchit } from 'lib'

import {
    API_ORDER
} from 'config/api'

export function createOrder(data, accessToken) {
    return fetchit(API_ORDER, data, 'POST', accessToken)
}

export function editOrder(data, orderId, accessToken) {
    return fetchit(API_ORDER+'/'+orderId, data, 'PATCH', accessToken)
}

export function getOrders({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_ORDER+'/?place='+placeId+'&sortBy=createdAt:desc&limit=100', null, 'GET', accessToken)
}

export function getUncompletedOrders({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_ORDER+'/uncompleted/?place='+placeId, null, 'GET', accessToken)
}
