import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import YoutubeEmbed from "components/YoutubeEmbed";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import {
  getDepartments,
  deleteDepartments,
  createDepartment,
  editDepartment,
} from "./services";
import { getHotelById } from "../locations/services";
import removeNil from "lib/removeNil";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import Form from "./Form";
import List from "components/List";
import { Button } from "components/Form";
import { FixedBottom } from "components/Layout/Styles";
import { GET_DEPARTMENTS } from "config/queries";

export default function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [newOverlayVisible, setNewOverlayVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [currentDepartement, setCurrentDepartment] = useState(null);
  const { t } = useTranslation();
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const selectedLocationDetails = useQuery(selectedLocation, () =>
    getHotelById({
      hotelId: selectedLocation,
      accessToken: access.token,
    })
  );

  const departmentList = useQuery(GET_DEPARTMENTS + selectedLocation, () =>
    getDepartments({
      placeId: selectedLocation,
      accessToken: access.token,
    })
  );

  const onNewMutation = useMutation(
    (data) => createDepartment(data, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_DEPARTMENTS + selectedLocation);
        setResetForm(true);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onEditMutation = useMutation(
    (data) => editDepartment(data, currentDepartement._id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_DEPARTMENTS + selectedLocation);
        setResetForm(true);
        setOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onDeleteMutation = useMutation(
    (departmentId) => deleteDepartments(departmentId, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_DEPARTMENTS + selectedLocation);
      },
      onError: (err) => {
        toast.error(t("update.error"));
      },
    }
  );

  const onNew = async (data) => {
    let dataCopy = { ...data };
    onNewMutation.mutate({
      ...removeNil(dataCopy),
      place: selectedLocation,
    });
    
    setNewOverlayVisible(false);
    setTabIndex(0);
  };

  const onEdit = async (data) => {
    let dataCopy = { ...data };
    onEditMutation.mutate({
      ...removeNil(dataCopy),
    });

  };

  const onDelete = (departmentId) => {
    onDeleteMutation.mutate(departmentId);
  };

  const onListClick = (item) => {
    let formatStaffForList = item.staff.map((e) => {
            return { label: e.email, value: e._id };
    })
    
    setOverlayVisible(true);
    setCurrentDepartment({ ...item, staff: formatStaffForList });
  };

  let tabs = [];

  if (!!departmentList?.data?.results) {
    tabs.push({
      title: "🏢 " + t("departments"),
      component: (
        <List
          items={departmentList.data.results}
          onDeleteBtn={onDelete}
          onClick={onListClick}
          showCalendar
        />
      ),
    });
  }

  tabs.push({
    title: t("help"),
    component: <YoutubeEmbed embedId="ScMzIvxBSi4" />,
  });

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}

        <FixedBottom>
          <Button onClick={() => setNewOverlayVisible(true)}>
            {t("form.add")} {t("department")}
          </Button>
        </FixedBottom>
      </Tabs>
      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <Form
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          members={selectedLocationDetails?.data?.staff}
          defaultValues={currentDepartement}
        />
      </Modal>

      <Modal visible={newOverlayVisible} setVisible={setNewOverlayVisible}>
        <Form
          onSubmit={onNew}
          buttonLabel={t("form.save")}
          resetForm={resetForm}
          members={selectedLocationDetails?.data?.staff}
        />
      </Modal>
    </Layout>
  );
}
