import { useMemo, useState } from "react";
import { useQuery, useMutation } from "react-query";
import { useParams, Link } from "react-router-dom";
import useSound from "use-sound";
import { useTranslation } from "react-i18next";
import useUser from "hooks/useUser";
import moment from "moment";
import useLocation from "hooks/useLocation";
import LottieNoOrders from "./LottieNoOrders";
import { getUncompletedOrders, editOrder } from "routes/orders/services";
import { getDepartmentById } from "../departments/services";
import { Button } from "components/Form";
import { Title } from "components/Typography";
import { Container } from "./Style";
import fanfareSound from "./sound_fanfare.mp3";

import { GET_ORDERS, GET_DEPARTMENTS } from "config/queries";

export default function Orders() {
  let { departmentId } = useParams();
  const { t } = useTranslation();
  const [play] = useSound(fanfareSound);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const orderList = useQuery(
    GET_ORDERS + selectedLocation + "pending",
    () =>
      getUncompletedOrders({
        placeId: selectedLocation,
        accessToken: access.token, //departmentId if defined from server
      }),
    {
      refetchInterval: 10 * 1000, //10 seconds
      enabled: isQueryEnabled,
    }
  );

  const myProducts = useMemo(() => {
    if (orderList?.data?.results?.[0]) {
      const results = orderList.data.results?.[0]?.orderItem;

      if (Array.isArray(results)) {
        play();
        setIsQueryEnabled(false);
        return results
          .map((r) => {
            return { ...r.product, note: r.note };
          })
          .filter((p) => departmentId === p.department);
      }
    }
    return null;
  }, [orderList?.data?.results, departmentId, play]);

  const onEditMutation = useMutation(
    (data) => editOrder(data, orderList?.data?.results?.[0]?._id, access.token),
    {
      onSuccess: () => {
        setIsQueryEnabled(true);
      },
      onError: () => {
        alert(t("update.error"));
      },
    }
  );

  const onEdit = async () => {
    onEditMutation.mutate({
      status: "shipped",
    });
  };

  const selectedDepartment = useQuery(GET_DEPARTMENTS + departmentId, () =>
    getDepartmentById({
      departmentId: departmentId,
      accessToken: access.token,
    })
  );

  return (
    <Container>
      {myProducts && orderList?.data?.results?.[0] ? (
        <div style={{ padding: 0, margin: 0 }}>
          <div
            style={{
              backgroundColor: "#110e11",
              position: "fixed",
              top: 0,
              width: "100%",
              left: 0,
              textAlign: "center",
            }}
          >
            <div>
              <Title dark>Chambre/Room {orderList.data.results[0].room}</Title>
            </div>
          </div>
          <div style={{ marginBottom: "120px" }} />
          <span>{moment(orderList.data.results[0].createdAt).fromNow()}</span>
          {myProducts.map((p) => (
            <>
              <OrderItem {...p} />
            </>
          ))}
          <div style={{ marginBottom: "170px" }} />
        </div>
      ) : (
        <div>
          <div
            style={{
              backgroundColor: "#110e11",
              position: "fixed",
              top: 0,
              width: "100%",
              left: 0,
              textAlign: "center",
            }}
          >
              <Title dark>{selectedDepartment?.data?.name}</Title>
              <hr />
            <Title dark>😃 {t("staff.waiting")}</Title>
          </div>
          <LottieNoOrders />
        </div>
      )}
      {myProducts && orderList?.data?.results?.[0] && (
        <>
          <div style={{ position: "fixed", bottom: 0, left: 0, width: "100%" }}>
            <Button
              height={"100px"}
              fontSize={"30px"}
              disabled={false}
              onClick={() => onEdit()}
            >
              ✔️
            </Button>
          </div>
        </>
      )}

      <CalendarView departmentId={selectedDepartment?.data?._id } />
      
    </Container>
  );
}

const OrderItem = (p) => {
  return (
    <div>
      <Title> {p.name}</Title>
      {p.note && <p>{p.note}</p>}
      <hr />
    </div>
  );
};

const CalendarView = ({departmentId}) => {
  return (
    <div style={{
      position: 'fixed',
      right: "20px",
      bottom: "20px",
    }}>

      <Link to={'/calendar/'+departmentId}>
      📅 Calendar View
      </Link>
    

    </div>
  )
}