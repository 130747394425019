import {  fetchit } from 'lib'

import {
    API_HOTEL
} from 'config/api'


export function createHotel(data, accessToken) {
    return fetchit(API_HOTEL, data, 'POST', accessToken)
}

export function editHotel(data, hotelId, accessToken) {
    return fetchit(API_HOTEL+'/'+hotelId, data, 'PATCH', accessToken)
}

export function getHotelsByStaffId({userId, accessToken}) {
    return fetchit(API_HOTEL+'/staff/'+userId, null, 'GET', accessToken)
}

export function getHotelById({hotelId, accessToken}) {
    return fetchit(API_HOTEL+'/'+hotelId, null, 'GET', accessToken)
}

export function addStaffToHotel(hotelId, data, accessToken) {
    return fetchit(API_HOTEL+'/'+hotelId+'/staff', data, 'PATCH', accessToken)
}

export function removeStaffFromHotel(hotelId, staffId, accessToken) {
    return fetchit(API_HOTEL+'/'+hotelId+'/'+staffId, null, 'DELETE', accessToken)
}

export function geocode({ address, city, zipcode, country, accessToken }) {
    if(!address || !country || !city ) return false
    return fetchit(API_HOTEL+'/geocode?address='+address+'&zipcode='+zipcode+'&country='+country+'&city='+city, null, 'GET', accessToken)
}