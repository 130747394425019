import { Button } from "components/Form";

const CustomWidget = ({ onChange }) => {
    return (
      <>
        <Button onClick={() => onChange(`https://`)}>Add Custom URL</Button>
      </>
    );
  };
  
  export default CustomWidget