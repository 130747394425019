import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row, Column } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button } from "components/Form";
import {  Text } from "components/Typography";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    onSubmit({ ...data});
  };

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Column>
            <Text>{t("form.fidelity.invoiceNumber")}</Text>
            <Input
              {...register("invoiceNumber")}
              placeholder={t("form.fidelity.invoiceNumber")}
              type="text"
              
            />
          </Column>
          <Column>
            <Text>{t("form.fidelity.averagePrice")}</Text>
            <Input
              {...register("averagePrice")}
              placeholder={t("form.fidelity.averagePrice")}
              type="number"
              
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Text>{t("form.fidelity.checkIn")}</Text>
            <Input
              {...register('checkIn')}
              placeholder={t("form.fidelity.checkIn")}
              type='date'
              
            />
          </Column>
          <Column>
            <Text>{t("form.fidelity.checkOut")}</Text>
            <Input
              {...register("checkOut")}
              placeholder={t("form.fidelity.checkOut")}
              type='date'
              
            />
          </Column>
        </Row>
        <Row>
            <Text>{t("form.fidelity.points")}</Text>
            <Input
              {...register("points")}
              placeholder={t("form.fidelity.points")}
              type="number"
              max={0}
              onChange={(e) => {
                if (e.target.value > 0) {
                  e.target.value = e.target.value * -1;
                }
              }}
            />
        </Row>
   
        <Spacing bottom={"xl"} />
        <Spacing bottom={"xl"} />


        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
