import styled from "styled-components";



export const Container = styled.div`
  padding: 10px;
  border-radius: 10px;
  padding: 1rem;
  background-color: ${(p) => (p.dark ? "rgba(0,0,0,.06)" : p.theme.colors.gray)};
  margin-bottom: ${(p) => (p.marginBottom ? "10px" : 0)}; 
`;

export const InfosContainer = styled.div`
  display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    text-align: center;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.3rem;
  `;
  

export const Title = styled.h3`
  margin: 0;
  color: ${(p) => p.theme.colors.light.text};
  font-weight: 300;
`;
export const Subtitle = styled.div`
  color: ${(p) => p.theme.colors.light.border};
  font-weight: 300;
  margin: 0;
`;
export const Description = styled.p`
  color: ${(p) => p.theme.colors.light.text};
  font-weight: 300;
`;
export const ActionButton = styled.button`
  margin: 30px 5px 0 0;
  padding: 8px 14px;
  background: ${(props) =>
    props.success
      ? props.theme.colors.success
      : (props) =>
          props.danger
            ? props.theme.colors.danger
            : "rgba(155, 155, 155, 0.2)"};
  color: ${(props) => props.theme.colors.light.contrastedText};
  cursor: pointer;
  border: 1px solid #fff;
  outline: 0;
  font-weight: 300;
  width: 100%;
  :hover {
    opacity: 0.8;
  }
`;
