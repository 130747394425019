export const roomTypes = ["room", "suite", "meeting"];
export const roomShapes = ['auditorium', 'banquet', 'boardroom', 'bоаrdrооm', 'cabaret', 'classroom', 'informal', 'reception', 'rectangle', 'round', 'sԛuаrе', 'theatre', 'u-shape']
export const roomFacilities = [
    "Spa",
    "Restaurant",
    "Parking",
    "Swimming pool",
    "Jacuzzi",
    "Public computer",
    "Disabled room",
    "Fitness room",
    "24 Hour security",
    "Catering service",
    "Terrace",
    "Conference and meeting",
    "Water purification system",
    "Gift shop",
    "Wi-Fi internet",
    "Banquet",
    "Bar",
    "Health club",
    "Sauna and steam bath",
    "Lounge",
    "Luggage storage",
    "Non-smoking rooms",
    "Pet friendly",
    "Smoking rooms"
]
export const roomAmenities = [
    "Parking",
    "Free Wifi",
    "Room Service",
    "24-Hour Guest Reception",
    "Complimentary Toiletries",
    "Healthy Breakfast",
    "Ample Wall Outlets",
    "Hair Styling Tools",
    "Flexible Checkout",
    "Pool",
    "Mini-fridge",
    "Complimentary Electronics Chargers",
    "Clothing Iron",
    "Business Facilities",
    "Transportation Information",
    "Free Breakfast",
    "Laundry Services",
    "Spa & Wellness",
    "Exercise Facilities",
    "Daily Newspaper",
    "Entertainment",
    "Complimentary Luggage storage",
    "Cribs & Cots",
    "Custom Offers",
    "Curated Experiences",
    "Fancy Bathrobes",
    "Kid-friendly",
    "Premium Bedding",
    "Stain Remover",
    "Pet-friendly",
    "Champagne Bar",
    "Valet Service",
    "Early check in",
    "Auto check in",
    "Bell desk",
    "Lobby seating",
    "Lobby internet",
    "Lobby food",
    "Concierge",
    "Business Center",
    "Laundry",
    "Wakeup call",
    "Gift Shop",
    "Restaurant breakfast",
    "Restaurant Lunch",
    "Restaurant dinner",
    "Bar",
    "Minibar",
    "Fridge",
    "Mineral Water",
    "Coffee maker",
    "Room Internet",
    "TV",
    "Radio",
    "Alarm",
    "Room desk",
    "Room light",
    "Movie on Demand",
    "Hair Dryer",
    "Bath robe",
    "Safe",
    "In room fitness equipment",
    "Fitness Center",
    "Express checkout",
    "Late checkout",
    "Folio under door",
    "Boarding pass printing"
]