import styled from "styled-components";
import { NavLink } from "react-router-dom";

// prettier-ignore

/**
 * Container div for holding Navigation Bar
 *
 * @param {string} maxWidth
 * @param {string} padding
 * @param {boolean} bordered
 * @param {boolean} color
 */
export const NavBar = styled.ul`
  position: relative;
  margin: 0 auto;
  list-style: none ;
  overflow-y: auto ;
  overflow-x: hidden ;
`;

export const NavBarElement = styled.li`
  margin-bottom: ${(p) => p.theme.spacing.xs};
  font-family: ${(p) => p.theme.font.body};
  a {
    text-decoration: none;
    color: ${(p) => p.theme.colors.light};
    font-weight: 100;
  }
`;

export const NavBarTitle = styled.h2`
  margin-top: ${(p) => p.theme.spacing.lg};
  margin-bottom: ${(p) => p.theme.spacing.sm};
  padding:0 ;
  font-family: ${(p) => p.theme.font.title};
  text-decoration: none;
  font-size: 17px;
  color: ${(p) => p.theme.colors.light.contrastedText};
  font-weight: 100;
  
`;

export const StyledActiveLink = styled(NavLink)`
  color: ${(p) => p.theme.colors.grey};
  &.active {
    color: ${(p) => p.theme.colors.white};
    border-bottom: 5px solid ${(p) => p.theme.colors.yellow};
  }
`;
