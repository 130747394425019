import { useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import {  Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error } from "components/Form";


export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm
}) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });



  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = async (data) => {
    onSubmit({ ...data });
  };

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          
          <Input
              {...register("customer", { required: true })}
              placeholder={t("form.fidelityCustomer")}
              required
            />
            {errors.fidelityCustomer && <Error>Customer is required</Error>}
        </Row>
        <Spacing bottom={"sm"} />
        <Button type="submit">{buttonLabel}</Button>

      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
