import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useForm, useFieldArray } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import { Input, Button, Error, TextArea, Select } from "components/Form";
import ModalGallery from "routes/media/ModalGallery";
import Gallery from "routes/media/Gallery";
import days from "config/days";
import getTimes from "config/times";

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
  categoryList,
}) {
  const { t } = useTranslation();
  const [media, setMedia] = useState([]);
  const timeList = useMemo(() => getTimes(), []);
  const {
    register,
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "openingHours", // unique name for your Field Array
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const mediaIds =
      media && media[0] && media[0]._id ? media.map((m) => m._id) : null;
    delete data.tags
    onSubmit({ ...data, media: mediaIds });
  };

  const handleGallery = (data) => {
    setMedia(data);
  };

  useEffect(() => {
    if (!!defaultValues?.media) {
      setMedia(defaultValues.media);
    }
  }, [defaultValues]);

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Input
            {...register("name", { required: true })}
            aria-invalid={errors.name ? "true" : "false"}
            placeholder={t("form.category.name")}
          />
          {errors.name && <Error>Category Name is required</Error>}
        </Row>
        <Row>
          <TextArea
            {...register("description")}
            aria-invalid={errors.description ? "true" : "false"}
            placeholder={t("form.category.description")}
          />
        </Row>

        <Row>
          {categoryList && categoryList[0] && (
            <Select
              {...register("parent")}
              placeholder={t("form.category.parent")}
              small
            >
              <option value={""}>{t("form.noparent")}</option>
              {categoryList.map((c, k) => (
                <option value={c._id} key={c + k}>
                  {c.name}
                </option>
              ))}
            </Select>
          )}
        </Row>
        <Spacing bottom={"sm"} />

        <Spacing bottom={"lg"} />
        <ModalGallery isMany onClick={handleGallery} />
        <Gallery medias={media} small />

        
        <label>
            {t("Tags")}
          <Input {...register("tagOne")} placeholder={t("Moroccan cuisine")} />
          <Input {...register("tagTwo")} placeholder={t("Vegetarian menu")} />
          
          <Input {...register("tagThree")} placeholder={t("Kids menu")} />
      
      
         
        </label>
        <label>
                {t("Icon name")}
                <Input {...register("iconName")} placeholder={t("fa-heart")} />
        </label>

        <label>
                {t("Background color")}
                <Input {...register("bgColor")} placeholder={t("#212145")} />
        </label>
      
    
        


       
       
      
       
       

        {fields.map((item, index) => (
          <div>
            <div key={item.id}>
              <label>
                {t("form.select.day")}
                <Select {...register(`openingHours.${index}.day`)} small>
                  {days.map((l) => (
                    <option key={l.name} value={l.value}>
                      {t(l.name)}
                    </option>
                  ))}
                </Select>
              </label>
              <label>
                {t("form.start.time")}
                <Select {...register(`openingHours.${index}.startTime`)} small>
                  {timeList.map((l) => (
                    <option key={l.name} value={l.value}>
                      {l.name}
                    </option>
                  ))}
                </Select>
              </label>
              <label>
                {t("form.end.time")}
                <Select {...register(`openingHours.${index}.endTime`)} small>
                  {timeList.map((l) => (
                    <option key={l.name} value={l.value}>
                      {l.name}
                    </option>
                  ))}
                </Select>
              </label>
              <label>
                {t("form.max.customer")}
                <Input
                  {...register(`openingHours.${index}.maxClientPerHour`)}
                  type="number"
                  placeholder="Max Customer Per hour"
                />
              </label>
            </div>
            <Button type="button" onClick={() => remove(index)} secondary>
              {t("form.delete")}
            </Button>
            <Spacing bottom={"lg"} />
          </div>
        ))}
        <Spacing bottom={"lg"} />

        <Button
          secondary
          type="button"
          onClick={() => {
            append({
              day: 0,
              startTime: 0,
              endTime: 0,
              maxClientPerHour: 0,
            });
          }}
        >
          + {t("opening.hours")}
        </Button>
        <Spacing bottom={"lg"} />
        <Button type="submit">{buttonLabel}</Button>
      </form>
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
