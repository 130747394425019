import {  fetchit } from 'lib'

import {
    API_ROOM
} from 'config/api'


export function createRoom(data, accessToken) {
    return fetchit(API_ROOM, data, 'POST', accessToken)
}

export function editRoom(data, roomId, accessToken) {
    return fetchit(API_ROOM+'/'+roomId, data, 'PATCH', accessToken)
}

export function getRooms({ placeId, accessToken,limit=8, page=0 }) {
    if (!placeId) return
    const url = `${API_ROOM}/?limit=${limit}&page=${page}&place=${placeId}`
    return fetchit(url, null, 'GET', accessToken)
}

export function deleteRooms(roomId, accessToken) {
    if(!roomId) return
    return fetchit(API_ROOM+'/'+roomId, null, 'DELETE', accessToken)
}
