import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "components/Form";
import { Spacing } from "components/Layout/Spacing";
import VideoPlayerWidget from "./widgets/VideoPlayer";
import CountDownWidget from "./widgets/CountDown";
import WeatherWidget from "./widgets/Weather";
import CustomWidget from "./widgets/Custom";
import ImageWidget from './widgets/ImagePlayer'
import { Title } from "components/Typography";

const widgetList = ["video", "countdown", "weather", "image", "custom"];

export default function WidgetUrlBuilder({ closeModal, onChange }) {
  const { t } = useTranslation();
  const [selectedWidget, setSelectedWidget] = useState();
  function handleInputChange(value) {
    onChange(value);
    closeModal();
  }
  return (
    <>
      <Title>{t("Content")}</Title> <Spacing bottom={"lg"} />
      <Select onChange={(e) => setSelectedWidget(e.target.value)}>
        <option>{t("form.select.widget")}</option>
        {widgetList.map((option) => (
          <option key={option} value={option}>
            {t(option)}
          </option>
        ))}
      </Select>
      <Spacing bottom={"sm"} />
      {selectedWidget === "video" && (
        <VideoPlayerWidget onChange={handleInputChange} />
      )}
      {selectedWidget === "countdown" && (
        <CountDownWidget onChange={handleInputChange} />
      )}
       {selectedWidget === "image" && (
        <ImageWidget onChange={handleInputChange} />
      )}
      {selectedWidget === "weather" && (
        <WeatherWidget onChange={handleInputChange} />
      )}
       {selectedWidget === "custom" && (
        <CustomWidget onChange={handleInputChange} />
      )}
    </>
  );
}
