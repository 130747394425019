import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getHotelsByStaffId } from "./services";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import { Select } from "components/Form";
import { GET_HOTELS } from "config/queries";

export default function App() {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const { user, access } = useUser((state) => state.user);
  const selected = useLocation((state) => state.selected);
  const setSelected = useLocation((state) => state.setSelected);
  const setHotelName = useLocation((state) => state.setHotelName);
  const { data, /*isLoading,*/ isSuccess, isError } = useQuery(GET_HOTELS, () =>
    getHotelsByStaffId({
      userId: user._id,
      accessToken: access.token,
    })
  );

  function handleOnchange(event) {
    const locationId = event.target.value;
    const location =
      data && data[0]
        ? data.filter((d) => d._id.toString() === locationId)[0]
        : {};
    if (location && location._id) {
      setHotelName(location.name)
      setSelected(location._id);
    } else {
      setSelected(null);
      navigate("/locations", { replace: true });
    }
    //window.location.reload()
  }

  useEffect(() => {
    if (!!selected && data && data[0]) {
      const updateSelected = data.filter(
        (d) => d._id.toString() === selected.toString()
      )[0];
      updateSelected?._id && setSelected(updateSelected._id);
    } else {
      //setSelected(null)
    }
  }, [data, selected, setSelected]);

  useEffect(() => {
    if (isError) {
      navigate('/auth')
      toast.error('You session has expired')
    }
  },[isError, navigate])

  return (
    <div style={{ textAlign: 'center' }}>
      {/*isLoading && !isError ? "loading..." : null*/}
      {isError ? "Oops, an error occurred..." : null}

      {isSuccess && !!data?.length ? (
        <>
          <Select defaultValue={selected} onChange={handleOnchange}>
            <option value={null}>{t("form.selectLocation")}</option>
            {data &&
              data[0] &&
              data.map((d, k) => (
                <option key={d.name + k} value={d._id}>
                  {d.name}
                </option>
              ))}
          </Select>
        </>
      ) : null}
    </div>
  );
}
