import { Title } from "./Typography";

const TitleWithSub = ({ title, sub }) => {
  return (
    <>
      <Title style={{ margin: 0 }}>{title}</Title>
      <span>{sub}</span>
    </>
  );
};

export default TitleWithSub;
