import { useState, useRef } from "react";
import styled from "styled-components";
import Modal from "components/Layout/Modal";
import MediaGallery from "./MediaGallery";
import useOutside from "hooks/useOutside";
import GalleryIcon from "components/Icons/GalleryIcon";
import GalleryIconTwo from "components/Icons/GalleryIconTwo"

export default function ShowGallery({
  onClick = () => {},
  isMany,
  isAbsolute,
  isVideo
}) {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const wrapperRef = useRef(null);
  useOutside(wrapperRef, onClickOutside);
  function onClickOutside() {
    setOverlayVisible(false);
  }

  function handleClick(url) {
    onClick(url);
    if (!isMany) {
      setOverlayVisible(false);
    }
  }

  return (
    <>
      {isAbsolute ? (
        <ContainerAbsolute onClick={() => setOverlayVisible(true)}>
          <GalleryIcon />
        </ContainerAbsolute>
      ) : (
        <ContainerRelative onClick={() => setOverlayVisible(true)}>
          <GalleryIconTwo />
        </ContainerRelative>
      )}

      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <MediaGallery onClick={handleClick} isMany={isMany} setVisible={setOverlayVisible} isVideo={ isVideo } />
      </Modal>
    </>
  );
}
const Container = styled.div`
  cursor: pointer;
`;

const ContainerAbsolute = styled(Container)`
  position: absolute;
  top: 0;
  right: 0;
`;

const ContainerRelative = styled(Container)`
  width:100%;
  max-height: 200px;
  background-color: ${props => props.theme.colors.light.sidebar} ;
  margin-bottom:30px ;
`;
