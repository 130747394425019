import {  fetchit } from 'lib'

import {
    API_DOCUMENTS
} from 'config/api'


export function createDocument(data, accessToken) {
    return fetchit(API_DOCUMENTS, data, 'POST', accessToken)
}

export function editDocument(data, documentId, accessToken) {
    return fetchit(API_DOCUMENTS+'/'+documentId, data, 'PATCH', accessToken)
}

export function getDocuments({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_DOCUMENTS+'/?place='+placeId, null, 'GET', accessToken)
}

export function deleteDocument(documentId, accessToken) {
    if(!documentId) return
    return fetchit(API_DOCUMENTS+'/'+documentId, null, 'DELETE', accessToken)
}
