
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useQueryClient,
  useMutation,
  useQuery
} from "react-query";
import { toast } from "react-toastify";
import {
  Tab,
  Tabs,
  TabList,
  TabPanel
} from "react-tabs";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import {
  editFidelityActivity,
  getFidelityActivities
} from "./services";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import YoutubeEmbed from "components/YoutubeEmbed";
import Form from "./Form";
import List from "components/List";
import {
  GET_FIDELITY_ACITIVITIES
} from "config/queries";
import { NoDataFound } from "components/LottieFiles";

export default function App() {
  const [page, setPage] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);
  const { t } = useTranslation();
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);
  
  const ActivitiesList = useQuery([GET_FIDELITY_ACITIVITIES + selectedLocation, page], () =>
  {
    let filter = {
      placeId: selectedLocation,
      accessToken: access.token,
      page,
    };
    return getFidelityActivities(filter)
  }
  );

  
  const formatActivities = (activities) =>  {
    const formatted = activities.map(activitie=> {
      return {
        ...activitie,
        name: `Invoice: ${activitie.invoiceNumber}`,
        tags: activitie.status
    }})
    return formatted
  }

  const onEditMutation = useMutation(
    (data) => editFidelityActivity(data, currentActivity._id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_FIDELITY_ACITIVITIES + selectedLocation);
        setResetForm(true);
        setOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );


  const onEdit = async (data) => {
    const { status } = data
    onEditMutation.mutate({status});
  };


  const onListClick = (item) => {
    if (!item) return
    setOverlayVisible(true);
    setCurrentActivity({...item });
  };

 

  let tabs = [];

  // get just pending activities
  
  const pendingActivities = ActivitiesList?.data?.results?.filter(act => act.status === "pending")
  tabs.push({
    title: "⌛ " + t("pending_fidelity_activities"),
    component: 
      pendingActivities?.length > 0 ?  <List
        onChangePage={(number) => setPage(number)}
        totalPages={ActivitiesList.data.totalPages}
        currentPage={page}
        items={formatActivities(pendingActivities)}
        onClick={onListClick}
      /> : <NoDataFound />
  });

  // get all activities
  tabs.push({
    title: "🏢 " + t("fidelity_activities"),
    component: 
      ActivitiesList?.data?.results?.length > 0 ? <List
        onChangePage={(number) => setPage(number)}
        totalPages={ActivitiesList.data.totalPages}
        currentPage={page}
        items={formatActivities(ActivitiesList.data.results)}
        onClick={onListClick}
      />
      : <NoDataFound />
  });

  tabs.push({
    title: t("help"),
    component:  <YoutubeEmbed embedId="ScMzIvxBSi4" />,
  });

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
       
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}
      </Tabs>
      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <Form
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          defaultValues={currentActivity}
          ActivitiesList={ActivitiesList?.data?.results}
        />
      </Modal>
    </Layout>
  );
}
