import { useMemo, useEffect } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { Spacing } from "components/Layout/Spacing";
import { Row, Column } from "components/Layout/Styles";
import { useTranslation } from "react-i18next";
import Card from "components/Card/Card";
import { Input, Button, Error, Select } from "components/Form";
import { Title } from "components/Typography";

const reservationStatuses = ["onboarding", "checkin", "checkout", "canceled"];

export default function Form({
  onSubmit,
  defaultValues,
  buttonLabel,
  resetForm,
}) {
  const { t } = useTranslation();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  useEffect(() => {
    if (!!defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    if (!!resetForm) {
      reset();
    }
  }, [resetForm, reset]);

  const onSubmitHelper = (data) => {
    const { email, room, arrival, departure, status } = data;
    onSubmit({ room, arrival, departure, email, status, notifyCustomer: true });
  };

  return (
    <>
      <Spacing bottom={"lg"} />
      <form onSubmit={handleSubmit(onSubmitHelper)}>
        <Row>
          <Column>
            <Input
              {...register("email", { required: true })}
              placeholder={t("form.email")}
              type="email"
              aria-invalid={errors.email ? "true" : "false"}
            />
            {errors.email && <Error>Email Address is required</Error>}
          </Column>
          <Column>
            <Input {...register("room")} placeholder={t("form.room.number")} />
          </Column>
        </Row>
        <Row>
          <Column>
            <Input
              {...register("arrival")}
              placeholder={t("form.arrival")}
              type="date"
              aria-invalid={errors.arrival ? "true" : "false"}
            />
            {errors.arrival && <Error>Arrival date is required</Error>}
          </Column>
          <Column>
            <Input
              {...register("departure")}
              placeholder={t("form.departure")}
              type="date"
              aria-invalid={errors.departure ? "true" : "false"}
            />
            {errors.departure && <Error>Departure date is required</Error>}
          </Column>
        </Row>
        <Row>
        <Column>
            <Input
              {...register("estimatedArrivalTime")}
              placeholder={t("form.estimatedArrivalTime")}
              type="time"
            />
          </Column>
          <Column>
            <Input {...register("flightNumber")} placeholder={t("form.flightNumber")} />
          </Column>

        </Row>
        <Row>
        <Column>
            <Input {...register("passeport")} placeholder={t("form.passeport")} />
          </Column>

        </Row>
     

        <Row>
          {defaultValues?.idScan &&
            defaultValues?.idScan[0] &&
            defaultValues?.idScan.map((i) => <ImageCard src={i} key={i} />)}
        </Row>

        <Spacing bottom={"xl"} />

        <Row>
          {defaultValues?.signature && <ImageCard src={defaultValues?.signature} width='100px' height={'100px'}/>}
        
        </Row>
        <p>{ defaultValues?.customer?.firstname } { defaultValues?.customer?.name }</p>

        <Spacing bottom={"xl"} />
        <Spacing bottom={"xl"} />
        <Spacing bottom={"xl"} />
        <Row>
          <Title>{t("update.status")}</Title>
          {reservationStatuses && reservationStatuses[0] && (
            <Select
              {...register("status")}
              placeholder={t("form.reservation.status")}
              small
            >
              <option disabled>{t("form.reservation.status")}</option>

              {reservationStatuses.map((c, k) => (
                <option value={c} key={c + k}>
                  {t(c)}
                </option>
              ))}
            </Select>
          )}
        </Row>

        <Button type="submit">{buttonLabel}</Button>
      </form>

      <Spacing bottom={"xl"} />
      <Spacing bottom={"xl"} />
      <Spacing bottom={"xl"} />
      {Array.isArray(defaultValues?.status) &&
        defaultValues.status.map((s, k) => {
          if (s?.updatedAt) {
            return (
              <Card
                key={s.updatedAt + k}
                title={s.status}
                subtitle={moment(s.updatedAt).format("MMMM Do YYYY, h:mm:ss a")}
                dark
                marginBottom
              />
            );
          } else {
            return null;
          }
        })}
    </>
  );
}

Form.prototype = {
  buttonLabel: PropTypes.string,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};

const ImageCard = ({ src, width, height }) => (
  <img src={src} style={{ width: width ? width : "250px", height: height ? height : "250px", cursor:'zoom-in' }} alt="id" onClick={()=>window.open(src, '_blank')}/>
);
