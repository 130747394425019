import {  fetchit } from 'lib'

import {
    API_REVIEW
} from 'config/api'

export function getReviews({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_REVIEW+'/?place='+placeId, null, 'GET', accessToken)
}

export function getReviewsStats({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_REVIEW+'/stats?place='+placeId, null, 'GET', accessToken)
}



