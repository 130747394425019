import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import YoutubeEmbed from "components/YoutubeEmbed";
import CustomerForm from "./customerForm";
import RedeemForm from "./redeemForm";
import { t } from "i18next";
import { useState } from "react";
import List from "components/List";
import useLocation from "hooks/useLocation";
import {
  getCustomer,
  getFidelityActivitiesByUserId,
  createFidelityActivity,
} from "./services";
import useUser from "hooks/useUser";
import { useMutation, useQuery, useQueryClient } from "react-query";
import removeNil from "lib/removeNil";
import { toast } from "react-toastify";
import Modal from "components/Layout/Modal";
import UserCard from "components/UserCard";
import { NoDataFound } from "components/LottieFiles";

export default function App() {
  const queryClient = useQueryClient();
  const [tabIndex, setTabIndex] = useState(0);
  const [emailOrId, setEmailOrId] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const selectedLocation = useLocation((state) => state.selected);
  const hotelName = useLocation((state) => state.hotelName);
  const { access } = useUser((state) => state.user);

  const formatActivities = (activities) => {
    if (Array.isArray(activities)) {
      const formatted = activities.map((activitie) => {
        return {
          ...activitie,
          name: `Invoice: ${activitie.invoiceNumber}`,
          tags: activitie.status,
        };
      });
      return formatted;
    }
    return []
  };

  const usersResults = useQuery("GET_USER_" + emailOrId, () =>
    getCustomer({ customer: emailOrId, placeId: selectedLocation, accessToken: access.token })
  );

  const activitiesList = useQuery("GET_ACTIVITIES_" + usersResults?.data?._id + selectedLocation, () =>
    getFidelityActivitiesByUserId({
      userId: usersResults?.data?._id,
      placeId: selectedLocation,
      accessToken: access.token,
    })
  );

  const createActivity = useMutation(
    (data) => createFidelityActivity(data, access.token),
    {
      onSuccess: () => {
        setOverlayVisible(false);
        toast.success(t("update.success"));
        queryClient.invalidateQueries("GET_ACTIVITIES_" + usersResults?.data?._id + selectedLocation);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onSearch = (data) => {
    const { customer } = data;
    if (customer) {
      setEmailOrId(customer);
    }
  };

  const onRedeem = (data) => {
    Object.assign(data, {
      hotelName: hotelName,
      user: usersResults?.data?._id,
      place: selectedLocation,
    });
    createActivity.mutate({
      ...removeNil(data),
    });
  };

  let tabs = [];

  tabs.push({
    title: "⌛ " + t("search_customer"),
    component: (
      <>
        <CustomerForm
          selectedLocation={selectedLocation}
          onSubmit={onSearch}
          buttonLabel={t("form.search")}
        />
        <Spacing bottom={"xl"} />

        {usersResults?.data?.name ? (
          <>
            <UserCard
              name={usersResults?.data?.name}
              email={usersResults?.data?.email}
              points={activitiesList?.data?.totalPoints?.toString()}
              onClick={() => setOverlayVisible(true)}
            />
            <Spacing bottom={"xl"} />
            {activitiesList?.data ? (
              <List items={formatActivities(activitiesList?.data?.results)} />
            ) : (
              <NoDataFound />
            )}
          </>
        ) : (
          <NoDataFound />
        )}
      </>
    ),
  });

  tabs.push({
    title: t("help"),
    component: <YoutubeEmbed embedId="ScMzIvxBSi4" />,
  });

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        draggable
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}
      </Tabs>

      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <RedeemForm
          selectedLocation={selectedLocation}
          onSubmit={onRedeem}
          buttonLabel={t("form.save")}
        />
      </Modal>
    </Layout>
  );
}
