import {  fetchit } from 'lib'

import {
    API_DEPARTMENT
} from 'config/api'


export function createDepartment(data, accessToken) {
    return fetchit(API_DEPARTMENT, data, 'POST', accessToken)
}

export function editDepartment(data, departmentId, accessToken) {
    return fetchit(API_DEPARTMENT+'/'+departmentId, data, 'PATCH', accessToken)
}

export function getDepartments({ placeId, accessToken }) {
    if(!placeId) return
    return fetchit(API_DEPARTMENT+'/?place='+placeId, null, 'GET', accessToken)
}

export function getDepartmentById({ departmentId, accessToken }) {
    if(!departmentId) return
    return fetchit(API_DEPARTMENT+'/'+departmentId, null, 'GET', accessToken)
}

export function deleteDepartments( departmentId, accessToken ) {
    return fetchit(API_DEPARTMENT+'/'+departmentId, null, 'DELETE', accessToken)
}
