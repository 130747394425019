export default function getTimes(offset=0) {
  var x = 15; //minutes interval
  var times = []; // time array
  var tt = offset; // start time

//loop to increment the time and push results in array
for (var i=0;tt<24*60; i++) {
  var hh = Math.floor(tt/60); // getting hours of day in 0-24 format
  var mm = (tt%60); // getting minutes of the hour in 0-55 format
  times[i] = {
    name: ("0" + (hh)).slice(-2) + ':' + ("0" + mm).slice(-2) , // pushing data in array in [00:00 - 12:00 AM/PM format],
    value: tt
  }
  tt = tt + x;
 
}
  return times
}