import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient, useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import useUser from "hooks/useUser";
import useLocation from "hooks/useLocation";
import {
  getKiosks,
  deleteKiosk,
  createKiosk,
  editKiosk,
} from "./services";
import removeNil from "lib/removeNil";
import { Spacing } from "components/Layout/Spacing";
import Layout from "components/Layout";
import Modal from "components/Layout/Modal";
import YoutubeEmbed from "components/YoutubeEmbed";
import Form from "./Form";
import List from "components/List";
import { Button } from "components/Form";
import { FixedBottom } from "components/Layout/Styles";
import { GET_KIOSKS } from "config/queries";

export default function App() {
  const [tabIndex, setTabIndex] = useState(0);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [newOverlayVisible, setNewOverlayVisible] = useState(false);
  const [currentKiosk, setCurrentKiosk] = useState(null);
  const { t } = useTranslation();
  const [resetForm, setResetForm] = useState(false);
  const queryClient = useQueryClient();
  const { access } = useUser((state) => state.user);
  const selectedLocation = useLocation((state) => state.selected);

  const kioskList = useQuery(GET_KIOSKS + selectedLocation, () =>
    getKiosks({
      placeId: selectedLocation,
      accessToken: access.token,
    })
  );

  const onNewMutation = useMutation(
    (data) => createKiosk(data, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_KIOSKS + selectedLocation);
        setResetForm(true);
        setNewOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onEditMutation = useMutation(
    (data) => editKiosk(data, currentKiosk._id, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_KIOSKS + selectedLocation);
        setResetForm(true);
        setOverlayVisible(false);
      },
      onError: () => {
        toast.error(t("update.error"));
      },
    }
  );

  const onDeleteMutation = useMutation(
    (kioskId) => deleteKiosk(kioskId, access.token),
    {
      onSuccess: () => {
        toast.success(t("update.success"));
        queryClient.invalidateQueries(GET_KIOSKS + selectedLocation);
      },
      onError: (err) => {
        toast.error(t("update.error"));
        console.error(err);
      },
    }
  );

  const onNew = async (data) => {
    let dataCopy = { ...data };
    onNewMutation.mutate({
      ...removeNil(dataCopy),
      place: selectedLocation,
    });
    setTabIndex(0);
  };

  const onEdit = async (data) => {
    const { name, widgets, showHeader } = data
    const widgetWithoutIds = widgets.map(w => {
      delete w._id
      return {...w }
    })
    onEditMutation.mutate({
      name,
      widgets: widgetWithoutIds,
      showHeader
    });
  };

  const onDelete = (kioskId) => {
    onDeleteMutation.mutate(kioskId);
  };

  const onListClick = (item) => {
    setOverlayVisible(true);
    setCurrentKiosk(item);
  };




  let tabs = [];

  if (kioskList?.data?.results && kioskList.data.results[0]) {
    tabs.push({
      title: "🏢 " + t("kiosks"),
      component: (
        <List
    
          items={kioskList.data.results.map((item) => {
            return {
              ...item,
              tags: [item.shortId, item?.widgets?.length || 0],
            };
          })}
          onDeleteBtn={onDelete}
          onClick={onListClick}
        />
      ),
    });
  }

  tabs.push({
    title: t("help"),
    component: <YoutubeEmbed embedId="ScMzIvxBSi4" />,
  });

  return (
    <Layout>
      <Spacing bottom={"lg"} />
      <Tabs
        style={{ padding: "20px" }}
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList>
          {tabs.map((t) => (
            <Tab key={t.title}>{t.title}</Tab>
          ))}
        </TabList>
        {tabs.map((t, k) => (
          <TabPanel key={t.title + k}>{t.component}</TabPanel>
        ))}

        <FixedBottom>
          <Button onClick={() => setNewOverlayVisible(true)}>
            {t("form.add")} {t("kiosk")}
          </Button>
        </FixedBottom>
      </Tabs>
      <Modal visible={overlayVisible} setVisible={setOverlayVisible}>
        <Form
          onSubmit={onEdit}
          buttonLabel={t("form.edit")}
          resetForm={resetForm}
          defaultValues={currentKiosk}
          kioskList={kioskList?.data?.results}
        />
      </Modal>
      <Modal visible={newOverlayVisible} setVisible={setNewOverlayVisible}>
        <Form
          onSubmit={onNew}
          buttonLabel={t("form.save")}
          resetForm={resetForm}
          kioskList={kioskList?.data?.results}
        />
      </Modal>
      
     
    </Layout>
  );
}
